import { Avatar, Box } from '@mui/joy';
import { Button, Slider, Typography } from '@mui/joy';
import Sheet from '@mui/joy/Sheet';
import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';

const AvatarInput = ({ onChange, src }) => {
  // Cropper
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1.1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [image, _setImage] = useState(null);
  const setImage = (newImage) => {
    if (image && image != src) URL.revokeObjectURL(image);
    _setImage(newImage);
  };

  const [imageToCrop, setImageToCrop] = useState(null);

  // conditionally sync internal image with props
  useEffect(() => {
    if (src) _setImage(src);
  }, [src]);

  // dropzone received file
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles[0]) {
      // onChange && onChange(acceptedFiles[0]);
      if (imageToCrop) URL.revokeObjectURL(imageToCrop);
      setImageToCrop(URL.createObjectURL(acceptedFiles[0]));
    }
  }, []);

  // Dropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ multiple: false, onDrop });

  // user clicked [done cropping]
  const doneCropping = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageToCrop, croppedAreaPixels);
      setImage(URL.createObjectURL(croppedImage));
      setImageToCrop(null);
      onChange && onChange(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, imageToCrop, onChange]);

  // sync cropper state, this gets called constantly as cropper is moved
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  let cropper_section = imageToCrop && (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Box sx={{ zIndex: 1000, position: 'relative', height: '200px', width: '200px' }}>
        <Cropper
          image={imageToCrop}
          crop={crop}
          zoom={zoom}
          aspect={1}
          showGrid={false}
          cropShape="round"
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
        />
      </Box>
      <Box sx={{ display: 'flex', padding: 2, flex: 1, gap: 2, flexDirection: 'column' }}>
        <div>
          <Typography variant="overline">Zoom</Typography>
          <Slider
            value={zoom}
            min={1}
            max={2}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
        <Button fullWidth color="primary" onClick={doneCropping}>
          Confirm
        </Button>
      </Box>
    </Box>
  );

  return (
    cropper_section || (
      <Box>
        <Box
          sx={{
            alignItems: 'center',
            gap: 2,
            display: 'flex',
          }}>
          {image && <Avatar src={image} alt="avatar" sx={{ border: 1, width: 70, height: 70 }} />}
          <Sheet
            sx={{
              padding: 2,
              width: '100%',
              textAlign: 'center',
              '& :hover': { cursor: 'pointer' },
            }}
            variant="outlined"
            {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography level="h4">Drop here!</Typography>
            ) : (
              <Typography level="body-md">
                Drag &amp; drop a profile image here, or click to select
              </Typography>
            )}
          </Sheet>
        </Box>
      </Box>
    )
  );
};

export default AvatarInput;

// This was taken from one of the examples for react-easy-crop
// https://codesandbox.io/s/q8q1mnr01w?file=/src/cropImage.js:805-2564
// Original top comment: This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
async function getCroppedImg(imageSrc, pixelCrop) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) return null;

  // set canvas size to intended output
  canvas.width = 512;
  canvas.height = 512;

  // draw image
  ctx.drawImage(
    // source
    image,
    // source start (crop)
    pixelCrop.x,
    pixelCrop.y,
    // source size (crop)
    pixelCrop.width,
    pixelCrop.height,
    // destination start
    0,
    0,
    // destination size
    512,
    512
  );

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(file);
    }, 'image/jpeg');
  });
}

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });
