import { useCalendar, useConnections } from '@groupthinkai/groupthink';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Card, Divider, Stack, Typography } from '@mui/joy';
import * as React from 'react';

import AuthButton from '@/components/reusable/AuthButton';

export default function ConnectCalendar({
  organization,
  setup = false,
  direction = 'row',
  aggregated = false,
  compact = false,
  actionText = 'Connect to',
}) {
  const {
    connections,
    catalog,
    isLoading: isLoadingConnections,
  } = useConnections(organization?.id);
  const { calendar, isLoading: isLoadingCalendar } = useCalendar(
    organization?.id,
    organization?.calendar_id
  );

  const [selectedDriver] = React.useState('');

  const googleDriverObject = catalog?.google_calendar;
  const outlookDriverObject = catalog?.outlook_calendar;

  const available_connections = calendar?.driver
    ? connections?.filter((connection) => {
        return connection.driver === calendar?.driver;
      })
    : [];

  const params = new URLSearchParams();
  if (setup) {
    params.append('setup', 'true');
  }
  if (aggregated) {
    params.append('aggregated', 'true');
  }

  if (!organization || !googleDriverObject) {
    console.warn('Organization or Google driver object not found', {
      organization,
      googleDriverObject,
    });
    return;
  }

  if (isLoadingConnections || isLoadingCalendar) {
    console.warn('Loading connections or calendar');
    return;
  }

  if (!setup && organization?.calendar_id) {
    console.warn('Organization already has a calendar');
    return;
  }

  // When there is at least one calendar connection available, do not offer to connect a Calendar.
  if (organization.calendar_id && calendar && connections && available_connections?.length > 0) {
    console.warn('Organization already has a calendar connection');
    return;
  }

  if (compact) {
    return (
      <Box>
        <Stack direction="row" spacing={1}>
          <AuthButton
            authUrl={googleDriverObject?.auth_url}
            params={params}
            sx={{ mt: 2 }}
            variant="outlined"
            color="neutral"
            startDecorator={
              <div
                style={{ width: '1rem', height: '1rem' }}
                dangerouslySetInnerHTML={{ __html: googleDriverObject.logo }}
              />
            }
            endDecorator={<ArrowForwardIcon />}
            redirectTo="/settings#calendar">
            {actionText} Google Calendar
          </AuthButton>
          <AuthButton
            sx={{ mt: 2 }}
            authUrl={outlookDriverObject?.auth_url}
            redirectTo="/settings#calendar"
            params={params}
            variant="outlined"
            color="neutral"
            startDecorator={
              <div
                style={{ width: '1rem', height: '1rem' }}
                dangerouslySetInnerHTML={{ __html: outlookDriverObject.logo }}
              />
            }
            endDecorator={<ArrowForwardIcon />}>
            {actionText} Outlook Calendar
          </AuthButton>
        </Stack>
      </Box>
    );
  }

  return (
    <Box>
      <Stack direction={{ xs: 'column', md: direction }} spacing={2}>
        <Card
          sx={{
            p: 2,
            flex: 1,
            backgroundColor: selectedDriver === 'google' ? 'tertiary.50' : 'white',
          }}
          variant={'outlined'}
          color={selectedDriver === 'google' && organization?.calendar_id ? 'tertiary' : 'neutral'}>
          <Stack alignItems={'baseline'}>
            <Stack direction="row" gap={1} sx={{ mb: 1 }}>
              <div
                style={{ width: '2.5rem', height: '2.5rem' }}
                dangerouslySetInnerHTML={{ __html: googleDriverObject.logo }}
              />
              <Stack>
                <Typography level="body-sm">Connect to</Typography>
                <Typography level="body-lg" fontWeight="lg">
                  Google Calendar
                </Typography>
              </Stack>
            </Stack>
            <Divider sx={{ mb: 1 }} />
            <Typography level="body-sm" gutterBottom>
              Google will ask your permission to allow Groupthink to access your Google Calendar.
              You can revoke this permission at any time.
            </Typography>
            <AuthButton
              authUrl={googleDriverObject?.auth_url}
              params={params}
              sx={{ mt: 2 }}
              variant="outlined"
              startDecorator={
                <div
                  style={{ width: '1rem', height: '1rem' }}
                  dangerouslySetInnerHTML={{ __html: googleDriverObject.logo }}
                />
              }
              endDecorator={<ArrowForwardIcon />}
              redirectTo="/settings#calendar">
              Connect
            </AuthButton>
          </Stack>
        </Card>
        <Card
          sx={{
            p: 2,
            flex: 1,
            backgroundColor: selectedDriver === 'outlook' ? 'tertiary.50' : 'white',
          }}
          variant={'outlined'}
          color={
            selectedDriver === 'outlook' && organization?.calendar_id ? 'tertiary' : 'neutral'
          }>
          <Stack alignItems={'baseline'}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              gap={1}
              sx={{ mb: 1 }}>
              <div
                style={{ width: '2rem', height: '2rem' }}
                dangerouslySetInnerHTML={{ __html: outlookDriverObject.logo }}
              />
              <Stack>
                <Typography level="body-sm">Connect to</Typography>
                <Typography level="body-lg" fontWeight="lg">
                  Microsoft Outlook
                </Typography>
              </Stack>
            </Stack>
            <Divider sx={{ mb: 1 }} />
            <Typography level="body-sm" gutterBottom>
              Microsoft will ask your permission to allow Groupthink to access your Outlook
              Calendar. You can revoke this permission at any time.
            </Typography>
            <AuthButton
              sx={{ mt: 2 }}
              authUrl={outlookDriverObject?.auth_url}
              redirectTo="/settings#calendar"
              params={params}
              variant="outlined"
              startDecorator={
                <div
                  style={{ width: '1rem', height: '1rem' }}
                  dangerouslySetInnerHTML={{ __html: outlookDriverObject.logo }}
                />
              }
              endDecorator={<ArrowForwardIcon />}>
              Connect
            </AuthButton>
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
}
