import { isElectron, isMobile, useOrganizations, useUser } from '@groupthinkai/groupthink';
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import {
  Avatar,
  Button,
  ListDivider,
  ListItemDecorator,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/joy';
import { ClickAwayListener, useMediaQuery } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';

import { DownloadDesktopAppModal } from '@/components/DownloadDesktopApp';
import { OrganizationSettingsModal } from '@/components/settings/OrganizationSettingsModal';
import { UserSettingsModal } from '@/components/settings/UserSettingsModal';
import { useAuth } from '@/hooks/auth';
import { useCurrentOrganization } from '@/hooks/organization';
import { usePrevious } from '@/hooks/util';

export default function UserMenu({ children = null, placement = 'bottom-end', autoLogin = true }) {
  const { user, isLoading: isLoadingUser } = useUser('me');
  const { organizations, isLoading: isLoadingOrgs } = useOrganizations();
  const [isDesktopAppDownloadModalOpen, setIsDesktopAppDownloadModalOpen] = React.useState(false);
  const [isUserSettingsModalOpen, setIsUserSettingsModalOpen] = React.useState(false);
  const [isOrganizationSettingsModalOpen, setIsOrganizationSettingsModalOpen] =
    React.useState(false);
  const router = useRouter();

  const theme = useTheme();
  const smPlus = useMediaQuery(theme.breakpoints.up('sm'));
  const lgPlus = useMediaQuery(theme.breakpoints.up('lg'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [currentOrganization, setCurrentOrganization] = useCurrentOrganization();
  const previousCurrentOrganization = usePrevious(currentOrganization);

  const organizationObject = organizations?.find((org) => org.id === currentOrganization);
  const isOrganizationsPickerVisible = organizations?.length > 1;
  const isLoading = isLoadingUser || isLoadingOrgs;

  const handleDownloadDesktopApp = () => {
    handleClose();
    setIsDesktopAppDownloadModalOpen(true);
  };

  const handleUserSettings = () => {
    handleClose();
    if (smPlus) {
      setIsUserSettingsModalOpen(true);
      setIsOrganizationSettingsModalOpen(false);
    } else {
      router.push('/settings');
    }
  };

  const handleOrganizationSettings = () => {
    handleClose();
    if (smPlus) {
      setIsUserSettingsModalOpen(false);
      setIsOrganizationSettingsModalOpen(true);
    } else {
      router.push(`/organizations/${currentOrganization}/settings`);
    }
  };

  // Detect when the route changes to close the menu
  React.useEffect(() => {
    const handleRouteChange = () => {
      setAnchorEl(null);
      setIsUserSettingsModalOpen(false);
      setIsOrganizationSettingsModalOpen(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const { logout } = useAuth({
    middleware: autoLogin ? 'auth' : '',
  });

  // if the currentOrganization changes, and the current route includes the old organizationId, let's update the route
  React.useEffect(() => {
    if (router.asPath.includes(previousCurrentOrganization)) {
      router.push(router.asPath.replace(previousCurrentOrganization, currentOrganization));
    }
  }, [currentOrganization]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        size="xs"
        variant="plain"
        color="neutral"
        onClick={handleClick}
        aria-controls={open ? 'composition-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        startDecorator={
          (isLoading || user?.avatar) && (
            <Avatar
              src={user?.avatar ?? ''}
              sx={{
                width: 32,
                height: 32,
              }}>
              <Skeleton loading={isLoading} />
            </Avatar>
          )
        }
        sx={{
          textTransform: 'none',
          justifyContent: 'flex-start',
          textAlign: 'left',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: { xs: '128px', lg: '224px' },
        }}>
        {/* maxWidth should be ~ maxWidth of button, minus avatar, minus button rounded corner radius, otherwise ellipsis won't render properly on overflow */}
        <Stack sx={{ maxWidth: { xs: '82px', lg: '170px' } }}>
          <Typography
            level={lgPlus ? 'body-md' : 'body-xs'}
            fontWeight={'bold'}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            <Skeleton
              loading={isLoadingUser}
              variant="text"
              level={lgPlus ? 'body-md' : 'body-xs'}
              width={100}
              sx={{ pb: 1 }}>
              {user?.name}
            </Skeleton>
          </Typography>
          <Typography
            level="body-xs"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            <Skeleton loading={isLoadingOrgs} variant="text" level="body-xs" width={100}>
              {organizationObject?.name}
            </Skeleton>
          </Typography>
        </Stack>
      </Button>
      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
              zIndex: 1000000,
              minWidth: '208px',
            }}
            aria-labelledby="user-button"
            placement={placement}>
            {children}
            {children && <ListDivider></ListDivider>}
            {isOrganizationsPickerVisible && (
              <>
                {children && <ListDivider></ListDivider>}
                <MenuItem disabled={true}>
                  <Typography level="body-md" fontWeight="lg">
                    Organization
                  </Typography>
                </MenuItem>
                {Object.values(organizations)?.map((organization) => (
                  <MenuItem
                    key={organization.id}
                    nested
                    role="menuitemradio"
                    aria-checked={currentOrganization === organization.id ? 'true' : 'false'}
                    onClick={() => {
                      setCurrentOrganization(organization.id);
                      handleClose();
                    }}>
                    <ListItemDecorator>
                      {currentOrganization === organization.id && <ArrowRightIcon />}
                    </ListItemDecorator>
                    <Typography
                      fontWeight={currentOrganization === organization.id ? 'bold' : 'inherit'}
                      sx={{
                        color: currentOrganization === organization.id ? 'primary.500' : 'inherit',
                      }}>
                      {organization.name}
                    </Typography>
                  </MenuItem>
                ))}
              </>
            )}
            {isOrganizationsPickerVisible && <ListDivider />}
            {user?.user_name && (
              <MenuItem component={Link} href={'/u/' + user?.user_name} onClick={handleClose}>
                Profile
              </MenuItem>
            )}
            <MenuItem onClick={handleUserSettings}>User settings</MenuItem>

            {currentOrganization && (
              <MenuItem onClick={handleOrganizationSettings}>Organization settings</MenuItem>
            )}
            <ListDivider />
            <MenuItem
              component={Link}
              href={`mailto:support@groupthink.com`}
              target="_blank"
              onClick={handleClose}>
              Email support
            </MenuItem>
            {!isMobile() && !isElectron && (
              <MenuItem onClick={handleDownloadDesktopApp}>Desktop app</MenuItem>
            )}

            <ListDivider />
            <MenuItem onClick={logout}>Sign out</MenuItem>
          </Menu>
        </ClickAwayListener>
      )}

      <DownloadDesktopAppModal
        open={isDesktopAppDownloadModalOpen}
        onClose={() => setIsDesktopAppDownloadModalOpen(false)}
      />

      <UserSettingsModal
        open={isUserSettingsModalOpen}
        onClose={() => setIsUserSettingsModalOpen(false)}
      />

      <OrganizationSettingsModal
        open={isOrganizationSettingsModalOpen}
        onClose={() => setIsOrganizationSettingsModalOpen(false)}
        organizationId={currentOrganization}
      />
    </>
  );
}
