import { useCalendar, useConnections, useOrganization } from '@groupthinkai/groupthink';
import { Box, Button, Card, Divider, Grid, Option, Select, Stack, Typography } from '@mui/joy';
import { useState } from 'react';
import * as React from 'react';

import ConfirmedAction from '@/components/ConfirmedAction';
import CalendarReauth from '@/components/calendar/CalendarReauth';
import { Switch } from '@/components/reusable';

export default function CalendarSettings({ organization, simple = false }) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { connections, deleteConnection, catalog } = useConnections(organization?.id);
  const { calendar, syncCalendar, updateCalendar, mutate, hasConnectedCalendar } = useCalendar(
    organization?.id,
    organization?.calendar_id
  );
  const { mutateList: mutateOrganization } = useOrganization(organization?.id);

  const drivers = {
    google_calendar: 'Google Calendar',
    outlook_calendar: 'Outlook Calendar',
  };

  const currentCalendarLogo = catalog?.[calendar?.driver]?.logo;

  const available_connections = calendar?.driver
    ? connections?.filter((connection) => {
        return connection.driver === calendar?.driver;
      })
    : [];

  let connections_by_id = {};
  available_connections?.forEach((connection) => {
    connections_by_id[connection.id] = connection;
  });

  const update = (vendor_id) => {
    updateCalendar({
      payload: { vendor_id: vendor_id },
      setIsUpdating,
      onSuccess: async () => {
        await mutateOrganization();
      },
    });
  };

  const updateSetting = (setting_key, setting_value) => {
    let settingspayload = { ...calendar?.settings };
    settingspayload[setting_key] = setting_value;
    updateCalendar({ payload: { settings: settingspayload }, setIsUpdating });
  };

  const changeConnection = (connection_id) => {
    updateCalendar({
      payload: { connection_id: connection_id },
      onSuccess: async () => {
        await mutateOrganization();
      },
      setIsUpdating,
    });
  };

  const destroyConnection = () => {
    deleteConnection(calendar?.connection?.id, {
      setIsDeleting,
      onSuccess: async () => {
        await mutate();
      },
    });
  };

  if (!calendar || !organization) {
    return;
  }

  if (available_connections && available_connections.length === 0) {
    return null;
  }

  if (calendar?.reauth) {
    return (
      <CalendarReauth
        driver={drivers[calendar?.driver]}
        organizationId={organization?.id}
        connectionId={calendar?.connection.id}
      />
    );
  }

  return (
    <Box>
      {!simple && (
        <Card variant="outlined" color="neutral" sx={{ p: 2, mb: 4 }}>
          {!calendar.connection && (
            <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 4 }}>
              <Grid xs={12} md={8}>
                <Typography level="body-lg">Credentials</Typography>
                <Typography level="body-md">
                  Change the connected account used to access your calendar
                </Typography>
              </Grid>
              <Grid xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Select
                  onChange={(e, value) => changeConnection(value)}
                  value={calendar?.connection?.id}
                  sx={{ mt: 2, width: '100%' }}
                  renderValue={() => {
                    // TODO: This is a temporary fix for https://github.com/Groupthinkai/frontend/issues/340 - when the underlying issue is identified in the backend, we need to provide some mechanism for the user to fix their connection instead of failing silently here
                    return calendar?.connection
                      ? connections_by_id?.[calendar?.connection?.id].name
                      : '';
                  }}
                  disabled={isUpdating}>
                  {available_connections?.map((connection) => (
                    <Option key={connection.id} value={connection.id}>
                      <Stack>
                        <Typography level="body-md">{connection.name}</Typography>
                        <Typography level="body-sm">{connection.id}</Typography>
                      </Stack>
                    </Option>
                  ))}
                </Select>
              </Grid>
            </Grid>
          )}
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid xs={12} md="auto">
              <Typography level="body-lg">Active Calendar</Typography>
            </Grid>
            <Grid xs={12} md={4}>
              <Select
                onChange={(e, value) => update(value)}
                value={calendar?.vendor_id ?? calendar?.vendor_id}
                sx={{ width: '100%' }}
                fullwidth="true"
                disabled={isUpdating}>
                {calendar?.connected_calendars.map((connected) => (
                  <Option key={connected?.vendor_id} value={connected?.vendor_id}>
                    {connected?.title}
                  </Option>
                ))}
              </Select>
            </Grid>
          </Grid>
          <>
            <Divider sx={{ my: 2 }} />
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid xs={12} md={8}>
                <Typography level="body-lg">Disconnect Calendar</Typography>
                <Typography level="body-md">
                  Deletes the calendar connection. Does not delete events or agendas.
                </Typography>
              </Grid>
              <Grid xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ConfirmedAction
                  onConfirm={destroyConnection}
                  title="Disconnect calendar?"
                  message="Groupthink will stop joining your meetings and you will not be able to add Groupthink to any other calendar events."
                  isConfirming={isDeleting}
                  buttonText={'Disconnect Calendar...'}
                  confirmButtonText={'Disconnect Calendar'}
                  variant="outlined"
                  size="md"
                />
              </Grid>
            </Grid>
          </>
        </Card>
      )}

      {hasConnectedCalendar && (
        <>
          {/*<Stack*/}
          {/*  sx={{ mb: 1 }}*/}
          {/*  direction={{ xs: 'row' }}*/}
          {/*  justifyContent="space-between"*/}
          {/*  alignItems="center">*/}
          {/*  <Typography level="h6">*/}
          {/*    {calendar?.settings.should_create_agendas_as_organizer*/}
          {/*      ? 'Groupthink Will Join Events You Host'*/}
          {/*      : 'Groupthink Will Not Join Events You Host'}*/}
          {/*  </Typography>*/}
          {/*  {calendar?.settings.should_create_agendas_as_organizer ||*/}
          {/*  calendar?.settings.should_create_agendas_as_attendee ? (*/}
          {/*    <Chip color="success" sx={{ mr: 1 }}>*/}
          {/*      Enabled*/}
          {/*    </Chip>*/}
          {/*  ) : (*/}
          {/*    <Chip color="danger" sx={{ mr: 1 }}>*/}
          {/*      Disabled*/}
          {/*    </Chip>*/}
          {/*  )}{' '}*/}
          {/*</Stack>*/}
          <Card variant="outlined" color="neutral" sx={{ p: 2, mb: 4 }}>
            <Grid container spacing={4} alignItems="center" justifyContent="space-between">
              <Grid xs={9}>
                <Typography
                  level="body-lg"
                  gutterBottom
                  startDecorator={
                    <span
                      style={{ width: '1.5rem', height: '1.5rem' }}
                      dangerouslySetInnerHTML={{ __html: currentCalendarLogo }}
                    />
                  }>
                  Automatically add Groupthink to calendar events you organize
                </Typography>
                <Typography level="body-sm">
                  {calendar?.settings.should_create_agendas_as_organizer
                    ? 'Currently Enabled'
                    : 'Currently Disabled'}
                </Typography>
              </Grid>
              <Grid xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Switch
                  checked={calendar?.settings.should_create_agendas_as_organizer}
                  onValueChange={(newValue) =>
                    updateSetting('should_create_agendas_as_organizer', newValue)
                  }
                  disabled={isUpdating}
                />
              </Grid>
              {!simple && (
                <Grid xs={9}>
                  <Typography level="body-lg">
                    Add Groupthink to events you <strong>attend</strong>
                  </Typography>
                  <Typography level="body-md">
                    Adds Groupthink to meetings you attend, even if you are not the organizer or
                    host.
                  </Typography>
                </Grid>
              )}
              {!simple && (
                <Grid xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Switch
                    checked={calendar?.settings.should_create_agendas_as_attendee}
                    onValueChange={(newValue) =>
                      updateSetting('should_create_agendas_as_attendee', newValue)
                    }
                    disabled={isUpdating}
                  />
                </Grid>
              )}
              {(calendar?.settings.should_create_agendas_as_organizer ||
                calendar?.settings.should_create_agendas_as_attendee) &&
                !simple && (
                  <>
                    <Grid xs={9}>
                      <Typography level="body-lg">Only add Groupthink to group events</Typography>
                      <Typography level="body-md">
                        Groupthink will only join meetings with at least two (2) attendees (you and
                        one more).
                      </Typography>
                    </Grid>
                    <Grid xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Switch
                        checked={calendar?.settings.minimum_attendees}
                        onValueChange={(newValue) => updateSetting('minimum_attendees', newValue)}
                        disabled={isUpdating}
                      />
                    </Grid>
                  </>
                )}
            </Grid>
          </Card>
          {!simple && (
            <Card variant="outlined">
              <Typography level="body-lg">Sync Events Now</Typography>
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                justifyContent="space-between"
                alignItems={{ xs: 'flex-end', md: 'center' }}>
                <Typography level={'body-md'}>
                  Manually retrieve new calendar data. (We sync as your calendar updates, so you
                  shouldn&apos;t need to do this often.)
                </Typography>

                <Button
                  loading={isSyncing}
                  disabled={isSyncing}
                  variant="outlined"
                  onClick={() => {
                    syncCalendar({ setIsSyncing });
                  }}
                  sx={{ minWidth: '200px', mt: { xs: 2, md: 0 } }}>
                  Sync Now
                </Button>
              </Stack>
            </Card>
          )}
        </>
      )}
    </Box>
  );
}
