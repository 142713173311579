import { useCalendar, useOrganization } from '@groupthinkai/groupthink';
import { Box, CircularProgress, Stack, TabList, TabPanel, Tabs, Tab, tabClasses } from '@mui/joy';
import * as React from 'react';

import UserBilling from '@/components/billing/UserBilling';
import CalendarSettings from '@/components/calendar/CalendarSettings';
import ConnectCalendar from '@/components/calendar/ConnectCalendar';
import ConnectionsList from '@/components/connections/ConnectionsList';
import UserNotifiersContainer from '@/components/notifiers/UserNotifiersContainer';
import WebhookList from '@/components/settings/webhooks/WebhooksList';
import DeleteUserForm from '@/components/users/DeleteUserForm';
import UserForm from '@/components/users/UserForm';
import { useCurrentOrganization } from '@/hooks/organization';

interface UserSettingsContainerProps {
  activeTab: string;
  setTab: (tab: string) => void;
  orientation?: 'horizontal' | 'vertical';
}
export default function UserSettingsContainer(props: UserSettingsContainerProps) {
  const { activeTab, setTab, orientation = 'horizontal' } = props;
  const [organization_id] = useCurrentOrganization();

  const { organization, isLoading: isLoadingOrganization } = useOrganization(organization_id);
  const { isLoading: isLoadingCalendar } = useCalendar(organization_id, organization?.calendar_id);

  const isVertical = orientation === 'vertical';

  return (
    <Tabs
      orientation={orientation}
      sx={{ backgroundColor: 'transparent', minWidth: 300, height: '100%' }}
      value={activeTab}
      onChange={(event, newValue: string) => setTab(newValue)}>
      <TabList
        sx={{
          position: isVertical ? 'fixed' : 'relative',
          pt: 2,
          [`&& .${tabClasses.root}`]: {
            flex: 'initial',
            bgcolor: 'transparent',
            '&:hover': {
              bgcolor: 'transparent',
            },
            [`&.${tabClasses.selected}`]: {
              color: 'primary.plainColor',
              '&::after': isVertical
                ? {}
                : {
                    height: '3px',
                    borderTopLeftRadius: '3px',
                    borderTopRightRadius: '3px',
                  },
            },
          },
        }}>
        <Tab value="profile">Profile</Tab>
        <Tab value="calendar">Calendar</Tab>
        <Tab value="billing">Billing</Tab>
        <Tab value="notifications">Notifications</Tab>
        <Tab value="connections">Connections & Integrations</Tab>
        <Tab value="webhooks">Webhooks</Tab>
      </TabList>
      <Box sx={{ marginLeft: isVertical ? 30 : 0 }}>
        <TabPanel value={'profile'}>
          <Stack spacing={8}>
            <UserForm />
            <DeleteUserForm />
          </Stack>
        </TabPanel>
        <TabPanel value={'calendar'}>
          {isLoadingOrganization || isLoadingCalendar ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress size="sm" />
            </Box>
          ) : (
            <>
              <CalendarSettings organization={organization} />
              <ConnectCalendar organization={organization} />
            </>
          )}
        </TabPanel>
        <TabPanel value={'billing'}>
          <UserBilling />
        </TabPanel>
        <TabPanel value={'notifications'}>
          <UserNotifiersContainer />
        </TabPanel>
        <TabPanel value={'connections'}>
          <ConnectionsList />
        </TabPanel>
        <TabPanel value={'webhooks'}>
          <WebhookList />
        </TabPanel>
      </Box>
    </Tabs>
  );
}
