import { useUserNotifiers, useUserNotifiersCatalog } from '@groupthinkai/groupthink';
import * as React from 'react';

import NotifiersContainer from '@/components/notifiers/NotifiersContainer';

export default function UserNotifiersContainer() {
  const { notifiers } = useUserNotifiers();
  const { notifications } = useUserNotifiersCatalog();

  const sortedNotifiers = notifiers?.sort((a, b) => {
    return a.triggering_event.localeCompare(b.triggering_event);
  });

  if (sortedNotifiers?.length > 0) {
    return (
      <NotifiersContainer
        notifications={notifications}
        notifiers={sortedNotifiers}
        notifiableId={'me'}
        notifiable={'user'}
      />
    );
  }
}
