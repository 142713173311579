import { useWorkspaceNotifiers, useWorkspaceNotifiersCatalog } from '@groupthinkai/groupthink';
import * as React from 'react';

import NotifiersContainer from '@/components/notifiers/NotifiersContainer';

export default function OrganizationNotifiersContainer({ organizationId }) {
  const { notifiers } = useWorkspaceNotifiers(organizationId);
  const { notifications } = useWorkspaceNotifiersCatalog(organizationId);

  if (notifiers?.length > 0) {
    return (
      <NotifiersContainer
        notifications={notifications}
        notifiers={notifiers}
        notifiableId={organizationId}
        organizationId={organizationId}
      />
    );
  }
}
