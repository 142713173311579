import { useConnections } from '@groupthinkai/groupthink';
import { ArrowRight } from '@mui/icons-material';
import {
  Box,
  Card,
  Chip,
  Dropdown,
  Link as FormattedLink,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/joy';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';

import { useCurrentOrganization } from '@/hooks/organization';

export default function ConnectionsList({ driverFilters = [] }) {
  const router = useRouter();
  const [organization] = useCurrentOrganization();
  const { connections, isLoading, catalog } = useConnections(organization);

  // Filter the catalog based on driverFilters
  const availableConnections = React.useMemo(() => {
    if (!catalog) return [];

    return Object.values(catalog)?.filter(
      (connection) => driverFilters.length < 1 || driverFilters.includes(connection.driver)
    );
  }, [catalog, driverFilters]);

  return (
    <Box>
      <Stack direction="column" spacing={2} sx={{ mb: 2 }}>
        {!isLoading && availableConnections.length === 0 && (
          <Typography level="body-sm">
            No available connections to add to this workspace.
          </Typography>
        )}
        {availableConnections.length > 0 && (
          <Dropdown>
            <MenuButton
              variant="outlined"
              color="primary"
              sx={{ textTransform: 'uppercase', maxWidth: '200px' }}>
              Add Connection
            </MenuButton>
            <Menu id="connections-menu" placement={'bottom-start'} size="lg" sx={{ zIndex: 1400 }}>
              {availableConnections.map((connection) => (
                <MenuItem
                  key={connection.driver}
                  onClick={() =>
                    router.push(
                      `/organizations/${organization}/connections/create/${connection.driver}`
                    )
                  }>
                  {connection.name}
                </MenuItem>
              ))}
            </Menu>
          </Dropdown>
        )}
      </Stack>
      <Box>
        {connections
          ?.filter(
            (connection) => driverFilters.length < 1 || driverFilters.includes(connection.driver)
          )
          .map((connection) => (
            <Card
              key={connection.id}
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'space-between',
                marginBottom: '1rem',
              }}
              variant={'outlined'}>
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: '1',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}>
                {catalog && connection && (
                  <Stack direction="row">
                    <div
                      style={{ width: '3rem', height: '3rem' }}
                      dangerouslySetInnerHTML={{ __html: catalog?.[connection.driver]?.logo }}
                    />

                    <Stack sx={{ mx: '1rem' }} spacing={1}>
                      <Typography level={'title-md'}>
                        {catalog?.[connection?.driver]?.name}
                      </Typography>
                      <Typography level={'body-sm'} sx={{ display: 'block' }}>
                        {connection?.name} {connection && <Chip>{connection.id}</Chip>}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Box>
              <FormattedLink
                overlay
                component={Link}
                underline="none"
                href={`/organizations/${organization}/connections/${connection.id}`}
                sx={{ color: 'text.tertiary' }}>
                <ArrowRight />
              </FormattedLink>
            </Card>
          ))}
      </Box>
    </Box>
  );
}
