import { Box, DialogTitle, Modal, ModalClose, ModalDialog } from '@mui/joy';
import * as React from 'react';
import { useState } from 'react';

import OrganizationSettingsContainer from '@/components/settings/OrganizationSettingsContainer';

interface OrganizationSettingsModalProps {
  open: boolean;
  onClose: () => void;
  initialTab?: string;
  organizationId?: string;
}

export function OrganizationSettingsModal(props: OrganizationSettingsModalProps) {
  const { open, onClose, initialTab = 'organization', organizationId } = props;
  // Manage tab state locally within the modal
  const [activeTab, setActiveTab] = useState(initialTab);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      sx={{
        display: { xs: 'initial', md: 'flex' },
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'scroll',
      }}
      disableAutoFocus
      disableEnforceFocus>
      <ModalDialog
        variant="outlined"
        sx={{
          boxShadow:
            '0px 0.6875rem 0.9375rem -0.4375rem rgba(0, 0, 0, 0.20), 0px 1.5rem 2.375rem 0.1875rem rgba(0, 0, 0, 0.14), 0px 0.5625rem 2.875rem 0.5rem rgba(0, 0, 0, 0.12)',
          minWidth: { xs: 'calc(100% - 32px)', md: '900px', lg: '900px' },
          overflow: 'scroll',
          maxHeight: { xs: '100%', sm: '80%' },
          minHeight: '80%',
          height: '80%',

          justifyContent: 'space-between',
          gap: '1.5rem',
          borderRadius: '8px',
        }}>
        <ModalClose size="lg" />
        <DialogTitle id="modal-title" level="title-md">
          Organization Settings
        </DialogTitle>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            height: '100%',
            borderTop: 'solid 1px #e6e6e6',
          }}>
          <OrganizationSettingsContainer
            orientation="vertical"
            activeTab={activeTab}
            setTab={setActiveTab}
            organizationId={organizationId}
          />
        </Box>
      </ModalDialog>
    </Modal>
  );
}
