import { useConnections, useOrganization } from '@groupthinkai/groupthink';
import {
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Link,
  Option,
  Select,
  Skeleton,
  Stack,
  Typography,
} from '@mui/joy';
import * as React from 'react';

export default function OrganizationNotificationSettings({ organizationId, driverFilters = [] }) {
  const [isUpdating, setIsUpdating] = React.useState(false);
  const { connections } = useConnections(organizationId);
  const {
    organization,
    updateOrganization,
    isLoading: isLoadingOrganization,
  } = useOrganization(organizationId);

  // Feature flag check for Slack notifications
  if (isLoadingOrganization || !organization?.features?.slack_notifications) {
    return (
      <Card variant="outlined">
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
          <Typography level={'body-md'}>Organization Slack Connection</Typography>
        </Stack>
        <Divider />
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
          <Typography level="body-md">
            <Skeleton loading={isLoadingOrganization}>
              Slack notifications are currently disabled.
            </Skeleton>
          </Typography>
        </Stack>
      </Card>
    );
  }

  const filtered = connections?.filter(
    (connection) => driverFilters.length < 1 || driverFilters.includes(connection.driver)
  );

  const changeConnection = (connection_id) => {
    updateOrganization({
      payload: {
        settings: {
          slack_connection_id: connection_id,
        },
      },
      setIsUpdating,
    });
  };

  console.log(`[notifiers] debug`, {
    filtered,
    organization,
    organizationId,
    driverFilters,
    isLoadingOrganization,
    connections,
    isUpdating,
  });

  return (
    <Card variant="outlined">
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
        <Typography level={'body-md'}>Organization Slack Connection</Typography>
      </Stack>
      <Divider />
      <FormControl orientation="horizontal" sx={{ justifyContent: 'space-between', my: 1 }}>
        <div>
          <Typography level="body-md">Connection</Typography>
          <FormHelperText sx={{ mt: 0 }}>
            We&apos;ll use this connection anytime we need to send a notification to Slack.
          </FormHelperText>
        </div>
        {filtered?.length > 0 && (
          <Select
            onChange={(e, value) => changeConnection(value)}
            value={organization?.settings?.slack_connection_id}
            sx={{ mt: 2, width: '100%' }}
            renderValue={() => {
              const c = filtered?.find(
                (connection) => connection.id === organization?.settings?.slack_connection_id
              );

              if (!c) {
                return 'No Connection Set';
              }

              return c.name + ' (via ' + c.created_by.email + ')';
            }}
            disabled={isUpdating}>
            {filtered?.map((connection) => (
              <Option key={connection.id} value={connection.id}>
                <Stack>
                  <Typography level="body-md">{connection.name}</Typography>
                  <Typography level="body-sm">{connection.id}</Typography>
                </Stack>
              </Option>
            ))}
          </Select>
        )}
        {(!filtered || filtered?.length < 1) && (
          <Button
            variant="plain"
            color={'danger'}
            size="sm"
            component={Link}
            href={`/organizations/${organization}/connections/create/slack`}>
            No connections available
          </Button>
        )}
      </FormControl>
      <Divider />
      <FormControl orientation="horizontal" sx={{ justifyContent: 'flex-end' }}>
        <Button
          disabled={isUpdating || !filtered || filtered?.length < 1}
          onClick={changeConnection}
          variant="outlined">
          Save
        </Button>
      </FormControl>
    </Card>
  );
}
