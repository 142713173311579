import { useBilling } from '@groupthinkai/groupthink';
import {
  AllInclusiveOutlined,
  ArrowDropDown,
  Check,
  Clear,
  DoneAllOutlined,
  WarningAmberOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Dropdown,
  IconButton,
  Link as FormattedLink,
  List,
  ListItem,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/joy';
import dayjs from 'dayjs';
import { useState } from 'react';

import { Modal } from '@/components/reusable';
import { useCurrentOrganization } from '@/hooks/organization';

const enum SubscriptionState {
  Inactive = 'inactive', // Free Plan
  Trialing = 'trialing', // Individual Pro Plan, trial
  Subscribed = 'active', // Individual Pro Plan, recurring
  Ending = 'ending', // Downgraded to Free Plan, active until end of billing cycle
}

const FREE_PLAN_FEATURES = [
  {
    decorator: <Check />,
    description: 'Chat with Groupthink AI',
  },
  {
    decorator: <Check />,
    description: 'Meeting Agendas',
  },
  {
    decorator: <Check />,
    description: 'Live Transcription, Notes, & Meeting Slides',
  },
  {
    decorator: <Check />,
    description: 'Host Groupthink Video Meetings up to 1 Hour',
  },
  {
    decorator: <Check />,
    description: 'Google Meet, Zoom, Microsoft Teams Support',
  },
  {
    decorator: <Check />,
    description: 'Meeting Summaries & Recordings Expire After 14 days',
  },
];

const EVERYTHING_FROM_FREE_FEATURE = {
  decorator: <DoneAllOutlined />,
  description: 'Everything from Free',
};

const INDIVIDUAL_PRO_PLAN_ADDITIONAL_FEATURES = [
  {
    decorator: <AllInclusiveOutlined />,
    description: 'Unlimited Length Groupthink Video Meetings',
    differentiator: '(will be limited to 1 hour)',
  },
  {
    decorator: <Check />,
    description: 'Retain Meeting Recaps & Recordings for 365 Days',
    differentiator: '(will expire after 14 days)',
  },
  {
    decorator: <Check />,
    description: 'Integrate with Slack & Zapier',
  },

  {
    decorator: <Check />,
    description: 'Automatic Agenda Contribution Reminders Support',
  },
  {
    decorator: <Check />,
    description: 'Custom Meeting Templates',
  },
];

const INDIVIDUAL_PRO_PLAN_FULL_FEATURES = [
  {
    decorator: <AllInclusiveOutlined />,
    description: 'Unlimited Length Groupthink Video Meetings',
  },
  {
    decorator: <Check />,
    description: 'Chat with Groupthink AI',
  },
  {
    decorator: <Check />,
    description: 'Meeting Summaries & Recordings Retained for 365 Days',
  },
  {
    decorator: <Check />,
    description: 'Meeting Agendas',
  },
  {
    decorator: <Check />,
    description: 'Custom Meeting Templates',
  },
  {
    decorator: <Check />,
    description: 'Live Transcription, Notes, & Meeting Slides',
  },
  {
    decorator: <Check />,
    description: 'Integrate with Slack & Zapier',
  },
  {
    decorator: <Check />,
    description: 'Google Meet, Zoom, Microsoft Teams Support',
  },
  {
    decorator: <Check />,
    description: 'Automatic Agenda Contribution Reminders Support',
  },
];

export default function OrganizationBilling() {
  return (
    <Stack spacing={4}>
      <BillingInformation />
      <PlanComparisonCards />
    </Stack>
  );
}

function PlanComparisonCards() {
  const [currentOrganization] = useCurrentOrganization();
  const { billing, isLoading: isLoadingBilling, startCheckout } = useBilling(currentOrganization);
  const [isCreating, setIsCreating] = useState(false);

  const subscriptionStatus = billing?.status;
  const isActive = subscriptionStatus && subscriptionStatus !== SubscriptionState.Inactive;

  const startCheckoutProcess = () => {
    startCheckout({
      setIsCreating,
      payload: {},
      onSuccess: (data) => {
        // @ts-ignore
        window.location = data.checkout_url;
      },
    });
  };

  if (isLoadingBilling || !billing) {
    return null;
  }

  if (isActive) {
    // Do not upsell a paid user
    return null;
  }

  return (
    <Stack spacing={4}>
      <Box
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 380px), 1fr))',
          gap: 8,
        }}>
        <SubscriptionPlanCard
          title={'Free'}
          subtitle={'Current Plan'}
          description={
            'Groupthink will join your calls, take notes, and generate recordings & recaps that expire in 14 days.'
          }
          chip={'🥦 The essentials'}
          features={FREE_PLAN_FEATURES}
          isHighlighted={false}
        />

        <SubscriptionPlanCard
          title={'Pro'}
          subtitle={'$30 / month'}
          description={
            'Best for individuals that want to review meeting notes and build an ongoing knowledge base about their organization.'
          }
          chip={'🍩 Treat yourself!'}
          features={[EVERYTHING_FROM_FREE_FEATURE, ...INDIVIDUAL_PRO_PLAN_ADDITIONAL_FEATURES]}
          isHighlighted={true}
          callToActionButton={
            <Button
              variant="solid"
              onClick={startCheckoutProcess}
              loading={isCreating}
              disabled={isCreating}>
              Start Upgrade...
            </Button>
          }
        />
      </Box>
    </Stack>
  );
}

function BillingInformation() {
  const [currentOrganization] = useCurrentOrganization();
  const {
    isLoading: isLoadingBilling,
    billing,
    cancelSubscription,
    mutateList,
  } = useBilling(currentOrganization);

  const subscriptionStatus = billing?.status;
  const isActive = subscriptionStatus && subscriptionStatus !== SubscriptionState.Inactive;

  const [isDeleting, setIsDeleting] = useState(false);
  const [cancellationErrors, setCancellationErrors] = useState(undefined);
  const [confirmDowngradeModalOpen, setConfirmDowngradeModalOpen] = useState(false);
  const [planDowngradedAlertModalOpen, setPlanDowngradedAlertModalOpen] = useState(false);

  const isEnding = subscriptionStatus === SubscriptionState.Ending;

  const handleSubscriptionCancellation = () => {
    cancelSubscription({
      setIsDeleting,
      setErrors: setCancellationErrors,
      payload: {},
      onSuccess: () => {
        mutateList();
        setConfirmDowngradeModalOpen(false);
        setPlanDowngradedAlertModalOpen(true);
      },
    });
  };

  if (isLoadingBilling || (billing && !isActive)) {
    return (
      <Skeleton loading={isLoadingBilling}>
        <Card variant="outlined">
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            alignItems={{ xs: 'flex-start', sm: 'center' }}>
            <Typography level={'body-md'}>You have no active subscriptions.</Typography>
            <Button variant="outlined" component={'a'} href={billing?.portal_url ?? '/'}>
              Manage Billing
            </Button>
          </Stack>
        </Card>
      </Skeleton>
    );
  }

  return (
    <Card
      size="lg"
      variant="outlined"
      sx={{
        flex: 1,
        p: 2,
      }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography level={'h4'}>Pro</Typography>

        <Dropdown>
          <ButtonGroup variant="outlined" color="primary">
            <Button component={'a'} href={billing?.portal_url ?? '/'}>
              Manage Billing
            </Button>

            <MenuButton
              loading={isDeleting}
              disabled={isDeleting}
              slots={{ root: IconButton }}
              slotProps={{ root: { variant: 'outlined', color: 'primary' } }}>
              <ArrowDropDown />
            </MenuButton>
          </ButtonGroup>
          <Menu placement={'bottom-end'}>
            <MenuItem
              onClick={() => setConfirmDowngradeModalOpen(true)}
              color="danger"
              disabled={isEnding}>
              Downgrade to Free...
            </MenuItem>
          </Menu>
          <ConfirmDowngradePlanModal
            open={confirmDowngradeModalOpen}
            handleModalClose={() => {
              if (cancellationErrors) {
                // Refresh billing
                mutateList();
              }
              setConfirmDowngradeModalOpen(false);
            }}
            handleDowngradeConfirmation={handleSubscriptionCancellation}
            isDeleting={isDeleting}
            cancellationErrors={cancellationErrors}
          />
          <PlanDowngradedAlertModal
            open={planDowngradedAlertModalOpen}
            handleModalClose={() => setPlanDowngradedAlertModalOpen(false)}
          />
        </Dropdown>
      </Stack>

      {isEnding ? (
        <FormattedLink
          level="body-md"
          color="warning"
          component={'a'}
          href={billing?.portal_url ?? '/'}
          underline="hover">
          <Typography
            level={'body-sm'}
            textColor="text.tertiary"
            fontStyle={'italic'}
            gutterBottom
            startDecorator={<WarningAmberOutlined color="warning" />}>
            Your subscription to the Pro plan is ending soon. You will continue to have access to
            {/* @ts-ignore */} paid features until{' '}
            {dayjs(billing?.subscription?.ends_at).format('MMMM D, YYYY')}.
          </Typography>
        </FormattedLink>
      ) : (
        <Typography level={'body-md'} gutterBottom>
          You are subscribed to the Pro plan.
        </Typography>
      )}
      <Divider />
      <List
        size="sm"
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
          mx: 'calc(-1 * var(--ListItem-paddingX))',
        }}>
        {INDIVIDUAL_PRO_PLAN_FULL_FEATURES.map((feature, index) => (
          <ListItem key={`feature-${index}`}>
            <ListItemDecorator>{feature.decorator}</ListItemDecorator>
            {feature.description}
          </ListItem>
        ))}
      </List>
      <Divider sx={{ mt: 2 }} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Typography level="body-md">
          <Typography fontWeight="bold">
            ${(Number(billing?.seats_qty ?? 1) * 30).toFixed(2)}
          </Typography>{' '}
          per month
        </Typography>
      </Box>
    </Card>
  );
}

function SubscriptionPlanCard({
  title,
  subtitle,
  description,
  chip = null,
  features,
  isHighlighted = false,
  callToActionButton = null,
  callToActionDetails = null,
  children = null,
}) {
  return (
    <Card size="lg" variant="outlined" color={isHighlighted ? 'primary' : 'neutral'}>
      {Boolean(chip) && (
        <Chip size="lg" variant="outlined">
          {chip}
        </Chip>
      )}
      {title && <Typography level={'h4'}>{title}</Typography>}
      {subtitle && (
        <Typography level={'body-lg'} gutterBottom>
          {subtitle}
        </Typography>
      )}
      {(title || subtitle) && <Divider inset="none" />}
      <CardContent sx={{ textAlign: 'start' }}>
        {description && (
          <Typography level={'body-md'} gutterBottom>
            {description}
          </Typography>
        )}
        {features && (
          <List
            size="sm"
            sx={{
              mx: 'calc(-1 * var(--ListItem-paddingX))',
            }}>
            {features.map((feature, index) => (
              <ListItem key={`feature-${index}`}>
                <ListItemDecorator>{feature.decorator}</ListItemDecorator>
                {feature.description}
              </ListItem>
            ))}
          </List>
        )}
        {children}
      </CardContent>
      <CardActions>
        {callToActionDetails}
        {callToActionButton}
      </CardActions>
    </Card>
  );
}

const ConfirmDowngradePlanModal = ({
  open,
  isDeleting,
  handleDowngradeConfirmation,
  handleModalClose,
  cancellationErrors,
}: {
  open: boolean;
  isDeleting: boolean;
  handleDowngradeConfirmation: () => void;
  handleModalClose: () => void;
  cancellationErrors: Groupthink.ErrorResponseContentErrors<'billing.cancel', 400> | undefined;
}) => {
  return (
    <Modal
      variant="dialog-warning"
      title="Downgrade plan?"
      description={
        'Your organization will lose access to the following features at the end of the billing cycle:'
      }
      open={open}
      onClose={handleModalClose}
      actions={
        <Box alignItems="center" justifyContent="flex-end" sx={{ display: 'flex', gap: 2 }}>
          {cancellationErrors?.['message'] && (
            <Typography level="body-md" color="danger">
              {cancellationErrors['message'].join(' ')}
            </Typography>
          )}
          <Button onClick={handleModalClose} variant="plain" color="neutral">
            Cancel
          </Button>
          <Button
            onClick={handleDowngradeConfirmation}
            variant="solid"
            color="danger"
            loading={isDeleting}
            disabled={isDeleting || Boolean(cancellationErrors)}>
            Downgrade
          </Button>
        </Box>
      }>
      <Box
        justifyContent={'center'}
        alignContent={'center'}
        justifyItems={'center'}
        alignItems="center">
        <List
          size="md"
          sx={{
            mx: 'calc(-1 * var(--ListItem-paddingX))',
          }}>
          {INDIVIDUAL_PRO_PLAN_ADDITIONAL_FEATURES.map((feature, index) => (
            <ListItem key={`feature-${index}`}>
              <ListItemDecorator>
                <Clear sx={{ color: '#cf4743' }} />
              </ListItemDecorator>
              {feature.description}
              <Typography fontWeight={'bold'}>{feature.differentiator}</Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    </Modal>
  );
};

const PlanDowngradedAlertModal = ({
  open,
  handleModalClose,
}: {
  open: boolean;
  handleModalClose: () => void;
}) => {
  return (
    <Modal
      title="Plan Downgraded"
      description={'Your subscription will remain active until the end of the billing cycle.'}
      open={open}
      onClose={handleModalClose}>
      <Box alignItems="center" justifyContent="flex-end" sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <Button onClick={handleModalClose} variant="outlined">
          Dismiss
        </Button>
      </Box>
    </Modal>
  );
};
