import { Box, Button, Typography } from '@mui/joy';
import Link from 'next/link';

const CalendarReauth = ({ driver, organizationId, connectionId }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3, // spacing between children
        m: 'auto', // center the box
        p: 2, // padding around the box
        textAlign: 'center', // center the text
      }}>
      <Typography level="h6">{driver} Connection Glitch</Typography>
      <Typography level="body-lg">
        Oops! Groupthink seems to have lost touch with your {driver}. Please reauthorize your
        account.
      </Typography>
      <Button
        component={Link}
        variant="soft"
        data-testid="fix-connection-button"
        href={`/organizations/${organizationId}/connections/${connectionId}`}>
        Fix This Connection
      </Button>
    </Box>
  );
};

export default CalendarReauth;
