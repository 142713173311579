import { DesktopMacOutlined as DesktopMacOutlinedIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/joy';
import * as React from 'react';

export default function Broadcast({ notifier, setChecked }) {
  const CHANNEL_KEY = 'broadcast';
  const CHANNEL_NAME = 'Desktop';

  const isEnabled = notifier?.channels.includes(CHANNEL_KEY);

  return (
    <Tooltip
      title={
        isEnabled
          ? `We'll send this notification to your browser and/or desktop app`
          : `Enable to send this notification to your browser and/or desktop app`
      }>
      <IconButton
        variant="solid"
        color={isEnabled ? 'primary' : 'neutral'}
        onClick={() => setChecked(notifier, CHANNEL_KEY, !isEnabled)}
        size="lg">
        <DesktopMacOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
}
