import { isMac, isWindows, isLinux } from '@groupthinkai/groupthink';
import { Apple as AppleIcon, Microsoft as MicrosoftIcon } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Link, Stack, Typography } from '@mui/joy';
import * as React from 'react';

import { Modal } from '@/components/reusable';

export function DownloadDesktopAppModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  return (
    <Modal open={open} onClose={onClose} variant="dialog">
      <Stack maxWidth="600px" marginX="auto" alignItems={'center'} spacing={4} marginY={2}>
        <Stack spacing={2}>
          <Typography level="h4">Get Groupthink on Your Desktop</Typography>
          <Typography level="body-lg">
            Get faster, distraction-free collaboration with instant AI-powered insights and
            notifications on{' '}
            {isMac() ? 'your Mac' : isWindows() ? 'Windows' : 'your Mac and Windows devices'}.
          </Typography>
        </Stack>
        <PlatformAwareDownloadDesktopAppButton />
      </Stack>
      {isMac() || isWindows() ? (
        <Stack direction="row" spacing={2} width="100%" mt={4} justifyContent={'flex-end'}>
          <Card variant="outlined" size="sm">
            <Typography level="body-md" fontWeight="md">
              {isMac() ? 'Using Windows?' : isWindows() ? 'Using a Mac?' : null}
            </Typography>
            <CardContent>
              <Link
                underline="always"
                // @ts-ignore
                color="tertiary"
                sx={{
                  flexGrow: 1,
                  justifyContent: 'center',
                }}
                target="_blank"
                href={isMac() ? '/downloads/windows' : isWindows() ? '/downloads/mac' : null}>
                Download for {isMac() ? 'Windows' : isWindows() ? 'Mac' : null}
              </Link>
            </CardContent>
          </Card>
        </Stack>
      ) : null}
    </Modal>
  );
}

export function DownloadForMacButton() {
  const [isClient, setIsClient] = React.useState(false);
  React.useEffect(() => {
    setIsClient(true);
  }, []);
  return (
    <Button
      component={Link}
      size="sm"
      variant={isClient ? (isMac() ? 'solid' : 'outlined') : 'solid'}
      // @ts-ignore
      color="tertiary"
      startDecorator={<AppleIcon />}
      href="/downloads/mac">
      Download for Mac
    </Button>
  );
}

export function DownloadForWindowsButton() {
  const [isClient, setIsClient] = React.useState(false);
  React.useEffect(() => {
    setIsClient(true);
  }, []);
  return (
    <Button
      component={Link}
      size="sm"
      variant={isClient ? (isWindows() ? 'solid' : 'outlined') : 'solid'}
      // @ts-ignore
      color="tertiary"
      startDecorator={<MicrosoftIcon />}
      href="/downloads/windows">
      Download for Windows
    </Button>
  );
}

export function PlatformAwareDownloadDesktopAppButton() {
  const [isClient, setIsClient] = React.useState(false);
  React.useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient || (!isMac() && !isWindows())) {
    return (
      <Stack direction="row" spacing={2}>
        <DownloadForMacButton />
        <DownloadForWindowsButton />
      </Stack>
    );
  }

  return isMac() ? <DownloadForMacButton /> : isWindows() ? <DownloadForWindowsButton /> : null;
}
