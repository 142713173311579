import { useUser, useOrganizationUsers } from '@groupthinkai/groupthink';
import { Box, Button, List, ListItem, Stack, Typography } from '@mui/joy';
import Link from 'next/link';
import * as React from 'react';
import { useState } from 'react';

import ConfirmedAction from '@/components/ConfirmedAction';
import UserCard from '@/components/users/UserCard';

export default function UserList({ organizationId }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const [isUpdating, setIsUpdating] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const [isDeleting, setIsDeleting] = useState(false);
  const { users, deleteUser } = useOrganizationUsers(organizationId);
  const { user: me } = useUser('me');

  if (!organizationId) return null;

  const destroy = (id) => {
    deleteUser(id, {
      isDeleting,
    });
  };

  return (
    <Box>
      <Typography level="body-md" sx={{ mb: 2 }}>
        Organization members can create new meetings, attach their calendar, and invite additional
        users. We recommend inviting only individuals who work for your company, since guests will
        still be able to access shared meetings and their agendas without Organization membership.
      </Typography>
      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="flex-end" sx={{ mb: 2 }}>
        <Button
          color="primary"
          component={Link}
          size="sm"
          href={`/organizations/${organizationId}/users/create`}>
          Invite New Member
        </Button>
      </Stack>
      <List>
        {users?.map((user) => (
          <ListItem key={`user-${user.id}`}>
            <UserCard user={user} gutterBottom>
              <UserCard.Details />
              {user.id !== me?.id && (
                <ConfirmedAction
                  onConfirm={() => destroy(user.id)}
                  title="Remove Member"
                  message={`Are you sure you want to remove ${user.name} from this Organization? They will lose access to all Agendas.`}
                  isConfirming={isDeleting}
                  buttonText={'Remove...'}
                  confirmButtonText={`Yes, remove ${user.name}`}
                  disable={user.id === me?.id}
                />
              )}
            </UserCard>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
