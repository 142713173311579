import { Box, Stack, Tab, tabClasses, Tabs, TabList, TabPanel, Typography } from '@mui/joy';
import * as React from 'react';

import OrganizationNotifiersContainer from '@/components/notifiers/OrganizationNotifiersContainer';
import OrganizationForm from '@/components/organizations/OrganizationForm';
import OrganizationNotificationSettings from '@/components/settings/notifications/OrganizationNotificationSettings';
import UserList from '@/components/users/UserList';

interface OrganizationSettingsContainerProps {
  organizationId: string;
  activeTab: string;
  setTab: (tab: string) => void;
  orientation?: 'horizontal' | 'vertical';
}
export default function OrganizationSettingsContainer(props: OrganizationSettingsContainerProps) {
  const { organizationId, activeTab, setTab, orientation = 'horizontal' } = props;

  const isVertical = orientation === 'vertical';

  return (
    <Tabs
      orientation={orientation}
      sx={{ backgroundColor: 'transparent', minWidth: 300, height: '100%' }}
      value={activeTab}
      onChange={(event, newValue: string) => setTab(newValue)}>
      <TabList
        sx={{
          position: isVertical ? 'fixed' : 'relative',
          my: 2,
          overflow: 'auto',
          [`&& .${tabClasses.root}`]: {
            flex: 'initial',
            bgcolor: 'transparent',
            '&:hover': {
              bgcolor: 'transparent',
            },
            [`&.${tabClasses.selected}`]: {
              color: 'primary.plainColor',
              '&::after': isVertical
                ? {}
                : {
                    height: '3px',
                    borderTopLeftRadius: '3px',
                    borderTopRightRadius: '3px',
                  },
            },
          },
        }}>
        <Tab value={'organization'}>Organization</Tab>
        <Tab value={'members'}>Members</Tab>
        <Tab value={'notifications'}>Notifications</Tab>
      </TabList>
      <Box sx={{ marginLeft: isVertical ? 20 : 0, marginRight: 2 }}>
        <TabPanel value={'organization'} sx={{ pr: 0 }}>
          <OrganizationForm organizationId={organizationId} />
        </TabPanel>
        <TabPanel value={'members'} sx={{ pr: 0 }}>
          <UserList organizationId={organizationId} />
        </TabPanel>
        <TabPanel value={'notifications'} sx={{ pr: 0 }}>
          <Stack spacing={4}>
            <Box>
              <Typography level={'body-md'} sx={{ mb: 1 }}>
                These notifications are shared with all members of your Organization, for all
                meetings with shared agendas
              </Typography>
              <OrganizationNotifiersContainer organizationId={organizationId} />
            </Box>
            <Box>
              <Typography level="body-lg" gutterBottom>
                Settings
              </Typography>
              <Typography level={'body-md'} sx={{ mb: 1 }}>
                Configure how notifications are sent across the Organization
              </Typography>
              <Stack spacing={1}>
                <OrganizationNotificationSettings
                  organizationId={organizationId}
                  driverFilters={['slack']}
                />
              </Stack>
            </Box>
          </Stack>
        </TabPanel>
      </Box>
    </Tabs>
  );
}
