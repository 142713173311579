import { useWebhooks } from '@groupthinkai/groupthink';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Typography,
} from '@mui/joy';
import Box from '@mui/joy/Box';
import { useState } from 'react';
import * as React from 'react';

import { Modal } from '@/components/reusable';

const CreateWebhookModal = ({ open, setOpen, organization }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [errors, setErrors] = useState([]);
  const { createWebhook, catalog } = useWebhooks(organization);
  const [name, setName] = useState('');
  const [events, setEvents] = useState([]);
  const [url, setUrl] = useState('');

  const create = () => {
    createWebhook({
      payload: {
        name,
        url,
        events,
        enabled: true,
      },
      setErrors,
      setIsCreating,
      onSuccess: () => {
        setOpen(false);
        setName('');
        setUrl('');
        setEvents([]);
      },
    });
  };

  return (
    <Modal title="Create a Webhook" open={open} onClose={() => setOpen(false)}>
      <Typography level="body-md" mb={3}>
        We&apos;ll send an HTTP POST request to the URL you specify whenever a triggering event
        occurs. You may specify which events trigger the webhook below.
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <FormLabel required htmlFor="webhook-url">
          Name
        </FormLabel>
        <Input
          placeholder="My Webhook"
          size="sm"
          value={name}
          error={Boolean(errors?.name)}
          onChange={(e) => setName(e.target.value)}
        />
        {errors.name && (
          <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[500] })}>
            {errors.name.join(' ')}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl fullWidth>
        <FormLabel required htmlFor="webhook-url">
          Webhook URL
        </FormLabel>
        <Input
          placeholder="https://webhooks.example.com/endpoint"
          size="sm"
          id="webhook-url"
          value={url}
          error={Boolean(errors?.url)}
          onChange={(e) => setUrl(e.target.value)}
        />
        {errors.url && (
          <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[500] })}>
            {errors.url.join(' ')}
          </FormHelperText>
        )}
      </FormControl>
      <Divider sx={{ my: 2, mx: -3 }} />
      <Typography level="body-md" mb={3} fontWeight="bold">
        Select the events that will trigger this webhook.
      </Typography>
      {catalog?.map((event) => (
        <Box key={event.key} variant="outlined" sx={{ display: 'flex', pb: 2 }}>
          <FormControl>
            <Checkbox
              sx={{ textTransform: 'capitalize' }}
              overlay
              checked={events.includes(event.key)}
              onChange={(e) => {
                if (e.target.checked) {
                  setEvents([...events, event.key]);
                } else {
                  setEvents(events.filter((key) => key !== event.key));
                }
              }}
              label={event.name}
            />
            <FormHelperText>{event.description}</FormHelperText>
          </FormControl>
        </Box>
      ))}

      <Divider sx={{ my: 2, mx: -3 }} />
      <Button
        loading={isCreating}
        disabled={isCreating || events.length < 1 || !url || !name}
        onClick={create}>
        Save Webhook
      </Button>
    </Modal>
  );
};

export default CreateWebhookModal;
