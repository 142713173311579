import { useConnections, useConnection, useOrganization } from '@groupthinkai/groupthink';
import {
  ErrorOutlined as ErrorOutlinedIcon,
  TagOutlined as TagOutlinedIcon,
  WarningOutlined as WarningOutlinedIcon,
} from '@mui/icons-material';
import { Badge, IconButton, Option, Select, Tooltip, Typography } from '@mui/joy';
import Link from 'next/link';
import * as React from 'react';

export default function Slack({
  notifier,
  slackConnectionId,
  setChecked,
  slackChannelId,
  setSlackChannelId,
  organizationId,
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const { connections, catalog } = useConnections(organizationId);
  const { organization, isLoading: isLoadingOrganization } = useOrganization(organizationId);
  const { connection } = useConnection(organizationId, slackConnectionId, false, true);

  const CHANNEL_KEY = 'slack';
  const CHANNEL_NAME = 'Slack';

  const isEnabled = notifier?.channels.includes(CHANNEL_KEY);

  // Slack connection details and channels
  const slack_connections = connections?.filter((connection) => connection.driver === 'slack');
  const slackChannels = [
    {
      id: '',
      name: 'DM All Attendees',
      is_private: true,
    },
    ...(connection?.meta?.channels ?? []),
  ];

  const isSlackEnabled = !isLoadingOrganization && organization?.features?.slack_notifications;
  const isAbleToChooseSlackConnection = Boolean(slack_connections?.length > 0);
  const isOrganizationConnectedToSlack = Boolean(slackConnectionId);

  if (!isSlackEnabled) {
    // Cannot turn on or off until they upgrade - do this for personal too!
    return (
      <Tooltip title="Want to enable this? Upgrade to a paid plan for Slack notifications!">
        <Badge color="danger" size="lg" badgeContent={<ErrorOutlinedIcon />}>
          <IconButton
            variant="solid"
            color={'neutral'}
            size="lg"
            component={Link}
            href={'/settings#billing'}>
            <TagOutlinedIcon />
          </IconButton>
        </Badge>
      </Tooltip>
    );
  } else if (isAbleToChooseSlackConnection) {
    // Display list of channels
    return (
      <Tooltip title="Select which Slack channel will receive this notification">
        <Select
          defaultValue="off"
          size="sm"
          value={slackChannelId}
          onChange={(event, value) => {
            let settings = {
              slack_connection_id: slackConnectionId,
            };
            if (value !== 'off') {
              settings = { ...settings, slack_channel_id: value };
            }
            setSlackChannelId(value);
            setChecked(notifier, CHANNEL_KEY, value !== 'off', settings);
          }}
          disabled={!isSlackEnabled}>
          <Option key={'off'} value={'off'}>
            Off
          </Option>
          {slackChannels.map((channel) => (
            <Option key={channel.id} value={channel.id}>
              {channel.name}
            </Option>
          ))}
        </Select>
      </Tooltip>
    );
  } else if (isOrganizationConnectedToSlack) {
    // We don't have a list of channels, but we can let them enable notifications anyway
    return (
      <IconButton
        disabled={!isSlackEnabled}
        variant="solid"
        color={isEnabled ? 'primary' : 'neutral'}
        onClick={() => setChecked(notifier, CHANNEL_KEY, !isEnabled)}
        size="lg">
        <TagOutlinedIcon />
      </IconButton>
    );
  } else if (!isOrganizationConnectedToSlack) {
    // We are not connected... so we need to show a warning and a link to connect
    return (
      <Tooltip title="Connect to Slack to enable notifications">
        <Badge color="warning" size="lg" badgeContent={<WarningOutlinedIcon />}>
          <IconButton
            variant="solid"
            color={'neutral'}
            size="lg"
            component={Link}
            href={`/organizations/${organization}/connections/create/slack`}>
            <TagOutlinedIcon />
          </IconButton>
        </Badge>
      </Tooltip>
    );
  } else {
    return (
      <Typography level="body-sm" textColor="text.tertiary">
        —
      </Typography>
    );
  }
}
