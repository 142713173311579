import { useWebhooks } from '@groupthinkai/groupthink';
import { Switch } from '@mui/joy';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useState } from 'react';

import ConfirmedAction from '@/components/ConfirmedAction';
import CreateWebhookModal from '@/components/settings/webhooks/CreateWebhookModal';
import WebhookLogsModal from '@/components/settings/webhooks/WebhookLogsModal';
import { useCurrentOrganization } from '@/hooks/organization';

export default function WebhookList() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const [isUpdating, setIsUpdating] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const [isDeleting, setIsDeleting] = useState(false);
  const [open, setOpen] = useState(false);
  const [logsOpen, setLogsOpen] = useState(false);
  const [organization] = useCurrentOrganization();
  const { webhooks, deleteWebhook, updateWebhook } = useWebhooks(organization);

  const destroy = (id) => {
    deleteWebhook(id, {
      isDeleting,
    });
  };

  const toggleActive = (webhook, active) => {
    updateWebhook(
      webhook.id,
      {
        payload: { ...webhook, enabled: active },
      },
      { isUpdating }
    );
  };

  return (
    <Box>
      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="flex-end" sx={{ mb: 2 }}>
        <Button color="primary" variant="outlined" size="sm" onClick={() => setOpen(true)}>
          Create New Webhook
        </Button>
      </Stack>
      <Typography level="body-md" sx={{ mb: 2 }}>
        Webhooks let you send data from your meeting and other events happening in Groupthink to
        other services. For example, you can send your meeting summary and notes to Zapier, which
        can then send them to Google Docs, Notion, Hubspot, Salesforce, or anywhere else you want.
      </Typography>
      <Box>
        {webhooks?.map((webhook) => (
          <Card
            key={webhook.id}
            variant="outlined"
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'space-between',
              justifyContent: 'center',
              marginBottom: '1rem',
              gap: 1,
            }}>
            <Box
              sx={{
                display: 'flex',
                flexGrow: '1',
                flexDirection: 'column',
                justifyContent: 'center',
              }}>
              <Stack direction="row">
                <Switch
                  checked={webhook.enabled}
                  disabled={isUpdating}
                  onChange={(e) => toggleActive(webhook, e.target.checked)}
                />
                <Stack sx={{ mx: '1rem' }}>
                  <Typography level={'body-md'}>{webhook.name}</Typography>
                  <Typography level={'body-sm'}>{webhook.url}</Typography>
                </Stack>
              </Stack>
            </Box>
            <Stack direction="row" justifyContent="center" spacing={1}>
              <Button variant="plain" color="neutral" onClick={() => setLogsOpen(webhook.id)}>
                Logs
              </Button>
              <ConfirmedAction
                onConfirm={() => destroy(webhook.id)}
                title="Delete Webhook"
                message={`Are you sure you want to delete the ${webhook.name} webhook?`}
                isConfirming={isDeleting}
                buttonText={'Delete...'}
                confirmButtonText={`Yes, remove webhook`}
              />
            </Stack>
          </Card>
        ))}
      </Box>
      <CreateWebhookModal open={open} setOpen={setOpen} organization={organization} />
      <WebhookLogsModal
        open={Boolean(logsOpen)}
        setOpen={setLogsOpen}
        organization={organization}
        webhookId={logsOpen}
      />
    </Box>
  );
}
