import {
  useWorkspaceNotifiers,
  useUserNotifiers,
  useAgendaNotifiers,
} from '@groupthinkai/groupthink';
import { CircularProgress, Divider, Typography, useTheme } from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';

import Broadcast from '@/components/notifiers//Channels/Broadcast';
import Mobile from '@/components/notifiers//Channels/Mobile';
import Mail from '@/components/notifiers/Channels/Mail';
import PersonalSlack from '@/components/notifiers/Channels/PersonalSlack';
import Slack from '@/components/notifiers/Channels/Slack';

export default function NotifierContainer({
  key,
  notifier,
  notifier_config,
  notifiableId,
  notifiable,
  organizationId,
  isLast = false,
}) {
  const theme = useTheme();
  const mdPlus = useMediaQuery(theme.breakpoints.up('md'));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const [isUpdating, setIsUpdating] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const [isDeleting, setIsDeleting] = useState(false);
  const [slackChannelId, setSlackChannelId] = useState(notifier?.settings.slack_channel_id);
  const { updateNotifier } =
    notifiable === 'user'
      ? useUserNotifiers()
      : notifiable === 'agenda'
      ? useAgendaNotifiers(notifiableId)
      : useWorkspaceNotifiers(notifiableId);

  const slackConnectionId = notifier?.settings?.slack_connection_id;

  const setChecked = (notifier, channel, active, settings = null) => {
    const channels = new Set(notifier.channels);
    active ? channels.add(channel) : channels.delete(channel);

    let payload = { ...notifier, channels: [...channels] };
    if (settings) {
      payload = { ...payload, settings: { ...payload.settings, ...settings } };
    }
    updateNotifier(notifier.id, {
      payload,
      setIsUpdating,
    });
  };

  if (!notifier_config) {
    return null;
  }

  // Check which notification channels are available for this notifier
  const hasSlack = Object.keys(notifier_config.notifiable?.[notifiable])?.includes('slack');
  const hasMail = Object.keys(notifier_config.notifiable?.[notifiable])?.includes('mail');
  const hasMobile =
    Object.keys(notifier_config.notifiable?.[notifiable])?.includes('mobile') &&
    notifiable === 'user';
  const hasBroadcast =
    Object.keys(notifier_config.notifiable?.[notifiable])?.includes('broadcast') &&
    notifiable === 'user';

  // Render the notifier row
  const renderNotifierInfo = () => {
    return (
      <>
        <Typography
          level="body-md"
          fontWeight="lg"
          endDecorator={(isUpdating || isDeleting) && <CircularProgress size="sm" />}>
          {notifier_config.name}
        </Typography>
        <Typography level="body-sm" sx={{ mt: 0.5 }}>
          {notifier_config.description}
        </Typography>
      </>
    );
  };

  const renderNotifierChannel = (channel) => {
    switch (channel) {
      case 'mail': {
        return hasMail ? (
          <Mail notifier={notifier} setChecked={setChecked} />
        ) : (
          <Typography level="body-sm" textColor="text.tertiary">
            —
          </Typography>
        );
      }

      case 'broadcast': {
        return hasBroadcast ? (
          <Broadcast notifier={notifier} setChecked={setChecked} />
        ) : (
          <Typography level="body-sm" textColor="text.tertiary">
            —
          </Typography>
        );
      }

      case 'mobile': {
        return hasMobile ? (
          <Mobile notifier={notifier} setChecked={setChecked} />
        ) : (
          <Typography level="body-sm" textColor="text.tertiary">
            —
          </Typography>
        );
      }

      case 'slack': {
        return hasSlack && notifiable === 'workspace' ? (
          <Slack
            notifier={notifier}
            slackChannelId={slackChannelId}
            setSlackChannelId={setSlackChannelId}
            slackConnectionId={slackConnectionId}
            setChecked={setChecked}
            organizationId={organizationId}
          />
        ) : hasSlack && notifiable === 'user' ? (
          <PersonalSlack notifier={notifier} setChecked={setChecked} />
        ) : (
          <Typography level="body-sm" textColor="text.tertiary">
            —
          </Typography>
        );
      }
    }

    return null;
  };

  if (mdPlus) {
    return (
      <tr key={key}>
        {/* First column: Notification name and description */}
        <td>{renderNotifierInfo()}</td>

        {/* Email column */}
        <td style={{ textAlign: 'center' }}>{renderNotifierChannel('mail')}</td>

        {/* Broadcast column */}
        <td style={{ textAlign: 'center' }}>{renderNotifierChannel('broadcast')}</td>

        {/* Mobile column */}
        <td style={{ textAlign: 'center' }}>{renderNotifierChannel('mobile')}</td>

        {/* Slack column */}
        <td style={{ textAlign: 'center' }}>{renderNotifierChannel('slack')}</td>
      </tr>
    );
  }

  return (
    <React.Fragment key={key}>
      <tr>
        {/* First column: Notification name and description */}
        <td colSpan={4}>{renderNotifierInfo()}</td>
      </tr>
      <tr>
        {/* Email column */}
        <td style={{ textAlign: 'center' }}>{renderNotifierChannel('mail')}</td>

        {/* Broadcast column */}
        <td style={{ textAlign: 'center' }}>{renderNotifierChannel('broadcast')}</td>

        {/* Mobile column */}
        <td style={{ textAlign: 'center' }}>{renderNotifierChannel('mobile')}</td>

        {/* Slack column */}
        <td style={{ textAlign: 'center' }}>{renderNotifierChannel('slack')}</td>
      </tr>
      {!isLast && (
        <tr>
          <td colSpan={4}>
            <Divider />
          </td>
        </tr>
      )}
    </React.Fragment>
  );
}
