import { useUser } from '@groupthinkai/groupthink';
import { MarkEmailUnreadOutlined as MarkEmailUnreadOutlinedIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/joy';
import * as React from 'react';

export default function Mail({ notifier, setChecked }) {
  const { user } = useUser('me');
  const CHANNEL_KEY = 'mail';
  const CHANNEL_NAME = 'Email';

  const isEnabled = notifier?.channels.includes(CHANNEL_KEY);

  return (
    <Tooltip
      title={
        isEnabled
          ? `We'll send this notification to ${user?.email}`
          : `Enable to send this notification to ${user?.email}`
      }>
      <IconButton
        variant="solid"
        color={isEnabled ? 'primary' : 'neutral'}
        onClick={() => setChecked(notifier, CHANNEL_KEY, !isEnabled)}
        size="lg">
        <MarkEmailUnreadOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
}
