import { axios } from '@groupthinkai/groupthink';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

if (typeof window !== 'undefined') {
  window.Pusher = Pusher;
  if (process.env.NEXT_PUBLIC_PUSHER_VERBOSE_LOGS) {
    Pusher.logToConsole = process.env.NEXT_PUBLIC_PUSHER_VERBOSE_LOGS === 'true';
  }
}

export function createPusherConnection() {
  if (!window?.Echo) {
    console.log(
      `[Echo] Initializing Echo with Pusher [Key: ${
        process.env.NEXT_PUBLIC_PUSHER_APP_KEY ? '✅' : '❌'
      }] [Cluster: ${process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER ? '✅' : '❌'}]`
    );
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.NEXT_PUBLIC_PUSHER_APP_KEY,
      cluster: process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER,
      forceTLS: true,
      authorizer: (channel) => {
        return {
          authorize: (socketId, callback) => {
            axios
              .post('/broadcasting/auth', {
                socket_id: socketId,
                channel_name: channel.name,
              })
              .then((response) => {
                if (process.env.NEXT_PUBLIC_PUSHER_VERBOSE_LOGS === 'true') {
                  console.log(`[Echo] Auth successful for ${channel.name}`);
                }
                callback(null, response.data);
              })
              .catch((error) => {
                console.error(`Auth failed for ${channel.name}:`, error);
                callback(error);
              });
          },
        };
      },
    });
  }
}
