import {
  useRealtimeCollection as _useRealtimeCollection,
  useRealtimeMutate as _useRealtimeMutate,
  useRealtimeResource as _useRealtimeResource,
  usePusher as _usePusher,
  usePresence as _usePresence,
} from '@groupthinkai/groupthink';

import { createPusherConnection } from '@/lib/pusher';

function listen(callback, channel, event) {
  // Ensure Echo is available
  if (!window?.Echo) {
    console.error('Echo is not initialized.');
    return () => {}; // Return a no-op cleanup
  }

  let privateChannel;
  try {
    // Attempt to get the private channel
    privateChannel = window.Echo.private(channel);
  } catch (error) {
    console.error(`Error accessing private channel "${channel}":`, error);
    return () => {}; // Return a no-op cleanup
  }

  if (process.env.NEXT_PUBLIC_PUSHER_VERBOSE_LOGS === 'true') {
    console.log('[Echo] Listening on channel', privateChannel, 'for event', event);
  }

  try {
    // Set up the event listener with nested error handling for the callback
    privateChannel.listen(event, (payload) => {
      try {
        callback(payload);
      } catch (callbackError) {
        console.error(
          `Error in callback for event "${event}" on channel "${channel}":`,
          callbackError
        );
      }
    });
  } catch (error) {
    console.error(`Error setting up listener for event "${event}" on channel "${channel}":`, error);
  }

  // Return a cleanup function with its own error handling
  return function cleanUp() {
    try {
      window.Echo.leave(channel);
    } catch (cleanupError) {
      console.error(`Error leaving channel "${channel}":`, cleanupError);
    }
  };
}

function joinPresence(channel, here, joining, leaving) {
  console.log(`joining presence channel ${channel}`);
  window?.Echo?.join(channel).here(here).joining(joining).leaving(leaving);

  return function cleanUp() {
    window?.Echo?.leave(channel);
  };
}

function onWhisper(callback, channel, event) {
  window?.Echo?.private(channel).listenForWhisper(event, (payload) => {
    callback(payload);
  });

  return function cleanUp() {
    // window?.Echo.leaveChannel(`private-${channel}`);
  };
}

/*

These are just examples

export const useDocumentMessages = (documentId, callback) => {
  useEffect(() => {
    createPusherConnection();
    return onWhisper(callback, `documents.${documentId}.messages`, 'SyncMessage');
  }, []);
};

export const useDocumentStateNotification = (documentId, event, callback) => {
  useEffect(() => {
    createPusherConnection();
    return onWhisper(callback, `documents.${documentId}.messages`, event);
  }, []);
};

export const sendSync = (documentId, payload) => {
  // eslint-disable-next-line no-undef
  window?.Echo.private(`documents.${documentId}.messages`).whisper('SyncMessage', payload);
};

export const sendDocumentStateNotification = (documentId, event, payload = {}) => {
  // eslint-disable-next-line no-undef
  window?.Echo.private(`documents.${documentId}.messages`).whisper(event, payload);
};

export const useJoinDocument = (userId, documentId, onJoin, onPeerJoining, onPeerLeaving) => {
  useEffect(() => {
    createPusherConnection();

    // eslint-disable-next-line no-undef
    window?.Echo.join(`documents.${documentId}`)
      .here((users) => {
        onJoin(users);
      })
      .joining((user) => {
        onPeerJoining(user);
      })
      .leaving((user) => {
        onPeerLeaving(user);
      })
      .error((error) => {
        console.error(error);
      });
  }, [userId, documentId]);
};
*/

/**
 * These are shims for the realtime hooks that use the createPusherConnection and listen methods.
 */
const useRealtimeCollection = (eventName, channel, mutate, url, mutationType, observationOnly) =>
  _useRealtimeCollection(
    eventName,
    channel,
    mutate,
    url,
    mutationType,
    observationOnly,
    createPusherConnection,
    listen
  );

const useRealtimeResource = (eventName, channel, mutate, url = null) =>
  _useRealtimeResource(eventName, channel, mutate, url, createPusherConnection, listen);

const useRealtimeMutate = (eventName, channel, url = null) =>
  _useRealtimeMutate(eventName, channel, url, createPusherConnection, listen);

const usePusher = ({ type, channel, callback = null, url = null }) =>
  _usePusher({ type, channel, callback, url, createPusherConnection, listen });

const usePresence = (channel) => _usePresence({ channel, createPusherConnection, joinPresence });

export {
  createPusherConnection,
  listen,
  joinPresence,
  usePusher,
  usePresence,
  useRealtimeCollection,
  useRealtimeMutate,
  useRealtimeResource,
};
