import { useUser, usePublicUser } from '@groupthinkai/groupthink';
import {
  LocationOnOutlined as LocationOnIcon,
  MailOutlined as MailIcon,
  WorkOutline as WorkIcon,
} from '@mui/icons-material';
import {
  Avatar as DefaultAvatar,
  Box,
  Card as MuiCard,
  Link as FormattedLink,
  Stack,
  Tooltip,
  Typography,
} from '@mui/joy';
import Link from 'next/link';
import * as React from 'react';
const UserCardContext = React.createContext();

function UserCard({
  children,
  user,
  variant = 'outlined',
  gutterBottom = false,
  sx: $sxOverride = {},
  ...props
}) {
  return (
    <UserCardContext.Provider value={{ user }}>
      <MuiCard
        variant={variant}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          marginBottom: gutterBottom ? '1rem' : 0,
          gap: 1,
          ...$sxOverride,
        }}
        {...props}>
        {children}
      </MuiCard>
    </UserCardContext.Provider>
  );
}

/**
 * Display the user's avatar.
 */
function Avatar(props) {
  const { user } = React.useContext(UserCardContext);

  return (
    <DefaultAvatar
      color={'info'}
      variant={'solid'}
      size={'lg'}
      {...{
        sx: { mr: 2 },
        alt: user.name,
        src: user.id !== 0 ? user?.avatar : null,
        ...props,
      }}
    />
  );
}

/**
 * Display the user's name and email address.
 * If the variant is set to "icons", display the user's email address with a mail icon and a gutterBottom underneath the user's name.
 */
function DisplayNameAndEmail({ variant = 'plain' }) {
  const { user } = React.useContext(UserCardContext);
  return (
    <div style={{ display: 'contents' }}>
      <Box
        sx={{
          display: 'flex',
          flexGrow: '1',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <UserDisplayName user={user} gutterBottom={variant === 'icons'} />
        {!!user.email && (
          <Typography
            level={'body-sm'}
            startDecorator={variant === 'icons' && <MailIcon />}
            gutterBottom>
            {user.email}
          </Typography>
        )}
      </Box>
    </div>
  );
}

/**
 * Display the user's avatar, name, and email address.
 * If the variant is set to "icons", display the user's email address with a mail icon and a gutterBottom underneath the user's name.
 */
function Details({ variant = 'plain' }) {
  return (
    <div style={{ display: 'contents' }}>
      <Avatar />
      <DisplayNameAndEmail variant={variant} />
    </div>
  );
}

/**
 * Displays additional details about the user from their public profile.
 */
function PublicDetails() {
  const { user } = React.useContext(UserCardContext);

  return (
    <div style={{ display: 'contents' }}>
      <Box
        sx={{
          display: 'flex',
          flexGrow: '1',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        {!!user.location && (
          <Typography level={'body-sm'} startDecorator={<LocationOnIcon />}>
            {user.location}
          </Typography>
        )}
        {!!user.job_title && (
          <Typography level={'body-sm'} startDecorator={<WorkIcon />}>
            {user.job_title}
          </Typography>
        )}
      </Box>
    </div>
  );
}

/**
 * If the user has a user name set, display the user name as a link to the user's profile, with their full name as a subtext.
 * Otherwise, display the user's full name.
 */
function UserDisplayName({ user, gutterBottom = false }) {
  const { user: currentUser } = useUser('me');
  if (!currentUser) {
    return null;
  }
  return user.user_name ? (
    <FormattedLink component={Link} href={`/u/${user.user_name}`} underline="hover">
      <Tooltip title={`${user.name} (@${user.user_name})`}>
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          level={'body-md'}
          fontWeight={'bold'}
          gutterBottom={gutterBottom}
          endDecorator={<Typography level={'body-sm'}>@{user.user_name}</Typography>}>
          {user.name.trim()}
          {currentUser.id === user.id ? ' (You)' : null}
        </Typography>
      </Tooltip>
    </FormattedLink>
  ) : (
    <Typography
      level={'body-md'}
      fontWeight={'bold'}
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      endDecorator={currentUser.id === user.id ? ' (You)' : null}>
      {user.name.trim()}
    </Typography>
  );
}

/**
 * Renders the provided children with a Tooltip that will display a popup UserCard on hover.
 * Falls back to rendering the children as is if the user is not loaded.
 */
function HoverCard({ user, children }) {
  if (!user) {
    return children;
  }
  return (
    <Tooltip
      placement="top-end"
      variant="outlined"
      arrow
      title={
        <UserCard user={user} variant="plain">
          <Stack direction="column" spacing={2}>
            <UserCard.Details variant="icons" />
            <UserCard.PublicDetails />
          </Stack>
        </UserCard>
      }>
      {children}
    </Tooltip>
  );
}

/**
 * Same as HoverCard, but looks up a user's public profile by id.
 * Falls back to rendering the children as is if the user is not loaded.
 */
function HoverCardById({ userId, children }) {
  const { user } = usePublicUser(userId);

  if (user) {
    return <HoverCard user={user}>{children}</HoverCard>;
  } else {
    return children;
  }
}

UserCard.Details = Details;
UserCard.PublicDetails = PublicDetails;
UserCard.Avatar = Avatar;
UserCard.DisplayNameAndEmail = DisplayNameAndEmail;

export default UserCard;
export { HoverCard, HoverCardById };
