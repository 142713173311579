import { useOrganization } from '@groupthinkai/groupthink';
import { TagOutlined as TagOutlinedIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/joy';
import * as React from 'react';

import { useCurrentOrganization } from '@/hooks/organization';

export default function PersonalSlack({ notifier, setChecked }) {
  const [currentOrganizationId] = useCurrentOrganization();
  const { organization, isLoading: isLoadingOrganization } = useOrganization(currentOrganizationId);

  const isSlackEnabled = !isLoadingOrganization && organization?.features?.slack_notifications;

  const CHANNEL_KEY = 'slack';
  const CHANNEL_NAME = 'Slack';

  const isEnabled = notifier?.channels.includes(CHANNEL_KEY);

  return (
    <Tooltip
      title={
        isSlackEnabled
          ? isEnabled
            ? `We'll send this notification to you in Slack, in a DM from Groupthink, if your
            Organization has added Groupthink to Slack.`
            : `Enable to send this notification to you in Slack, in a DM from Groupthink, if your
            Organization has added Groupthink to Slack.`
          : "Slack notifications are currently not available for this organization's pricing plan."
      }>
      <IconButton
        disabled={!isSlackEnabled}
        variant="solid"
        color={isEnabled ? 'primary' : 'neutral'}
        onClick={() => setChecked(notifier, CHANNEL_KEY, !isEnabled)}
        size="lg">
        <TagOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
}
