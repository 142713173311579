import { useOrganization, useUser } from '@groupthinkai/groupthink';
import {
  Alert,
  Button,
  Card,
  Chip,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Typography,
} from '@mui/joy';
import { Snackbar } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';

export default function OrganizationForm({ organizationId, onSave = null }) {
  const { organization, updateOrganization } = useOrganization(organizationId);

  const { user } = useUser('me');

  const [name, setName] = useState('');
  const [allow_access_domain, setAllowAccessDomain] = useState(null);

  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [didSave, setDidSave] = useState(false);
  const isDirty =
    name != organization?.name || allow_access_domain != organization?.allow_access_domain;

  const canEnableAllowAccessDomain = useMemo(() => {
    if (!user) return false;
    if (organization?.allow_access_domain) return true;

    const domainFilter = /^(gmail|yahoo|hotmail|outlook|icloud|msn|live|me|mac|aol)\.\w+$/;
    const domain = user?.email?.split('@')[1];
    return !domainFilter.test(domain);
  }, [user?.email]);

  useEffect(() => {
    if (organizationId && organization?.name) {
      setName(organization.name);
      setAllowAccessDomain(organization.allow_access_domain);
    }
  }, [organization, organizationId]);

  const submitForm = async (event) => {
    event.preventDefault();
    let payload = {
      name,
      allow_access_domain,
    };

    updateOrganization({
      payload,
      setErrors,
      setIsLoading,
      onSuccess: () => {
        setDidSave(true);
        onSave && onSave();
      },
    });
  };

  const toggleAutoJoin = async () => {
    setAllowAccessDomain(allow_access_domain ? null : user?.email?.split('@')[1]);
  };

  return (
    <form onSubmit={submitForm} disabled={isLoading}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <FormControl required>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="name"
              name="name"
              autoComplete="organization-name"
              value={name}
              error={errors?.name ?? false}
              onChange={(event) => setName(event.target.value)}
              disabled={false}
            />
            {errors?.name ? (
              <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[500] })}>
                {errors?.name.join(' ')}
              </FormHelperText>
            ) : null}
          </FormControl>
          {(allow_access_domain || canEnableAllowAccessDomain) && (
            <FormControl sx={{ mt: 2 }}>
              <FormLabel>
                <span>Auto-join</span>
                {allow_access_domain && (
                  <Chip size="sm" color="success" sx={{ ml: 1 }}>
                    Enabled
                  </Chip>
                )}
              </FormLabel>
              <Card variant="outlined">
                {allow_access_domain ? (
                  <>
                    <Typography variant="body-md">
                      Users with <Typography color="primary">@{allow_access_domain}</Typography>{' '}
                      email addresses will be automatically invited to this organization.
                    </Typography>
                    <div>
                      <Button
                        color="primary"
                        variant="outlined"
                        size="xs"
                        sx={{ px: 1 }}
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={toggleAutoJoin}>
                        Disable auto-join for {user?.email?.split('@')[1]}
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <Typography variant="body-md">
                      Enable to allow users with{' '}
                      <Typography color="primary">@{user?.email?.split('@')[1]}</Typography> email
                      addresses to automatically join this organization.
                    </Typography>
                    <div>
                      <Button
                        color="primary"
                        size="xs"
                        sx={{ px: 1 }}
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={toggleAutoJoin}>
                        Enable auto-join for {user?.email?.split('@')[1]}
                      </Button>
                    </div>
                  </>
                )}
                {allow_access_domain != organization?.allow_access_domain && (
                  <Typography level="body-sm">Changes will take effect on save.</Typography>
                )}
                {errors?.allow_access_domain ? (
                  <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[500] })}>
                    {errors?.allow_access_domain.join(' ')}
                  </FormHelperText>
                ) : null}
              </Card>
            </FormControl>
          )}
        </Grid>
        <Grid xs={12} alignItems="flex-end" sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            type="submit"
            color="primary"
            disabled={!isDirty || isLoading}
            loading={isLoading}>
            Save
          </Button>
        </Grid>
      </Grid>

      <Snackbar open={didSave} autoHideDuration={6000} onClose={() => setDidSave(false)}>
        <Alert onClose={() => setDidSave(false)} severity="success" sx={{ width: '100%' }}>
          Organization settings saved!
        </Alert>
      </Snackbar>
    </form>
  );
}
