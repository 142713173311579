import { useWebhook } from '@groupthinkai/groupthink';
import { Divider, Grid, Modal, ModalClose, Sheet, Stack, Typography } from '@mui/joy';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import * as React from 'react';

const WebhookLogsModal = ({ open, setOpen, organization, webhookId }) => {
  const { webhook } = useWebhook(organization, webhookId);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        display: { xs: 'initial', md: 'flex' },
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'scroll',
      }}>
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: 'md',
          p: 3,
          boxShadow: 'lg',
          minWidth: { xs: 'auto', lg: '900px' },
          maxWidth: { xs: 'auto', lg: '1200px' },
          margin: { xs: '120px 24px auto', md: '0' },
        }}>
        <ModalClose variant="outlined" />
        <Typography id="modal-title" level="title-lg" textColor="inherit" fontWeight="lg">
          Webhook Logs
        </Typography>
        <Typography id="modal-title" level="body-md" textColor="inherit" mb={3}>
          {webhook?.name}
        </Typography>
        <Divider sx={{ my: 2, mx: -3 }} />
        <AccordionGroup>
          {webhook?.logs
            ?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
            .map((log) => (
              <Accordion key={log.id}>
                <AccordionSummary>
                  <Stack justifyContent="center">
                    <Typography level="body-md">
                      <Chip
                        color={log.response.status_code < 300 ? 'success' : 'danger'}
                        size="sm"
                        sx={{ mr: 1 }}>
                        {log.response.status_code}
                      </Chip>
                      <span style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                        {log.method}
                      </span>{' '}
                      {log.url}{' '}
                    </Typography>
                    <Typography level="body-sm">{log.created_at} UTC</Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid xs={12} lg={12} sx={{ my: 2 }}>
                      <Typography level="body-sm" fontWeight="bold">
                        Headers
                      </Typography>
                      <Box>
                        {Object.keys(log.headers).map((key) => (
                          <Typography key={key} level="body-sm">
                            {key}: {log.headers[key]}
                          </Typography>
                        ))}
                      </Box>
                    </Grid>
                    <Grid xs={12} lg={6}>
                      <Typography level="body-sm" fontWeight="bold">
                        Request
                      </Typography>
                      <Box sx={{ mt: 1, maxHeight: '300px', overflow: 'scroll' }}>
                        <Typography level="body-sm">
                          {JSON.stringify(log.payload, null, 2)}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={12} lg={6}>
                      <Typography level="body-sm" fontWeight="bold">
                        Response
                      </Typography>
                      <Box sx={{ mt: 1, maxHeight: '300px', overflow: 'scroll' }}>
                        <Typography level="body-sm">
                          {JSON.stringify(log.response.body, null, 2)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
        </AccordionGroup>
      </Sheet>
    </Modal>
  );
};

export default WebhookLogsModal;
