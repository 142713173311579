import { useUser } from '@groupthinkai/groupthink';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Box,
  ModalDialog,
  ModalClose,
  Divider,
} from '@mui/joy';
import { Stack } from '@mui/system';
import { useState } from 'react';

import { Modal } from '@/components/reusable';

export default function DeleteUserForm() {
  const { user, deleteUser } = useUser('me');
  const [isConfirmingAccountDeletion, setIsConfirmingAccountDeletion] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteUser({
        setErrors,
        setIsDeleting,
        onSuccess: () => {
          // Handle successful deletion (e.g., redirect to home page)
          alert('Account deleted successfully, redirecting to home page...');
          window.location.href = '/';
        },
      });
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Card variant="soft" color="danger">
      <Typography level="h5">Danger Zone</Typography>
      <Divider inset="none" />
      <CardContent>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography color="danger">{errors?.error}</Typography>
          <Button
            color="danger"
            variant="solid"
            size="sm"
            onClick={() => setIsConfirmingAccountDeletion(true)}>
            Permanently Delete Account...
          </Button>
        </Stack>
      </CardContent>
      <Modal
        variant="dialog-warning"
        title="Delete account?"
        description="This cannot be undone. Your Groupthink account will be permanently deleted."
        open={isConfirmingAccountDeletion}
        onClose={() => setIsConfirmingAccountDeletion(false)}
        actions={
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setIsConfirmingAccountDeletion(false)}
              disabled={isDeleting}>
              Cancel
            </Button>
            <Button variant="solid" color="danger" onClick={handleDelete} disabled={isDeleting}>
              {isDeleting ? 'Deleting...' : 'Delete account for ' + user?.email}
            </Button>
          </Box>
        }></Modal>
    </Card>
  );
}
