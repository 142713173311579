import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FacebookIcon from '@mui/icons-material/Facebook';
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkIcon from '@mui/icons-material/Link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
import RedditIcon from '@mui/icons-material/Reddit';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Box, Button, IconButton, Input, Stack } from '@mui/joy';
import React, { useState, useEffect } from 'react';

const socialPlatforms = {
  facebook: { icon: FacebookIcon, regex: /facebook\.com/ },
  twitter: { icon: TwitterIcon, regex: /twitter\.com/ },
  linkedin: { icon: LinkedInIcon, regex: /linkedin\.com/ },
  instagram: { icon: InstagramIcon, regex: /instagram\.com/ },
  youtube: { icon: YouTubeIcon, regex: /youtube\.com/ },
  pinterest: { icon: PinterestIcon, regex: /pinterest\.com/ },
  reddit: { icon: RedditIcon, regex: /reddit\.com/ },
  github: { icon: GitHubIcon, regex: /github\.com/ },
  whatsapp: { icon: WhatsAppIcon, regex: /whatsapp\.com/ },
  telegram: { icon: TelegramIcon, regex: /t\.me/ },
};

const SocialLinksInput = ({ value, onChange }) => {
  const [links, setLinks] = useState(value || ['']);

  useEffect(() => {
    onChange(links);
  }, [links, onChange]);

  useEffect(() => {
    setLinks(value || []);
  }, [value]);

  const addLink = () => {
    setLinks([...links, '']);
  };

  const updateLink = (index, newValue) => {
    const updatedLinks = [...links];
    updatedLinks[index] = newValue;
    setLinks(updatedLinks);
  };

  const removeLink = (index) => {
    const updatedLinks = links.filter((_, i) => i !== index);
    setLinks(updatedLinks);
  };

  const getPlatformIcon = (link) => {
    for (const [platform, { icon: Icon, regex }] of Object.entries(socialPlatforms)) {
      if (regex.test(link)) {
        return <Icon />;
      }
    }
    return <LinkIcon />;
  };

  return (
    <Stack spacing={2} my={1}>
      {links?.map((link, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {getPlatformIcon(link)}
          <Input
            fullWidth
            placeholder="Enter social media link"
            value={link}
            onChange={(e) => updateLink(index, e.target.value)}
          />
          <IconButton onClick={() => removeLink(index)} color="danger">
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Button
        startDecorator={<AddIcon />}
        size="sm"
        variant="outlined"
        onClick={addLink}
        sx={{ alignSelf: 'flex-start' }}>
        Add Social Link
      </Button>
    </Stack>
  );
};

export default SocialLinksInput;
