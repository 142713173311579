import { usePublicUser, useUser } from '@groupthinkai/groupthink';
import { Check, Close } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardOverflow,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalDialog,
  ModalClose,
  Skeleton,
  Snackbar,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy';
import Link from '@mui/joy/Link';
import { Grid } from '@mui/material';
import NextLink from 'next/link';
import { useEffect, useState } from 'react';

import SocialLinksInput from './SocialLinksInput';

import AvatarInput from '@/components/users/AvatarInput';

export default function UserForm({
  children = null,
  simple = false,
  buttonText = 'Save Profile',
  onSave = () => {},
  externalLoading = false,
}) {
  const { user, updateUser, deleteUser } = useUser('me');

  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [existingPassword, setExistingPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [avatar, setAvatar] = useState('');
  const [bio, setBio] = useState('');
  const [collaborationStyle, setCollaborationStyle] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [location, setLocation] = useState('');
  const [website, setWebsite] = useState('');
  const [socialLinks, setSocialLinks] = useState([]);
  const [company, setCompany] = useState({});

  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [didSave, setDidSave] = useState(false);
  const [isConfirmingAccountDeletion, setIsConfirmingAccountDeletion] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    user: usernameCheck,
    isLoading: isUsernameCheckLoading,
    isError: isUsernameCheckError,
  } = usePublicUser(username);
  const isUsernameTaken = usernameCheck && usernameCheck.id != user?.id;
  const isUsernameValid = username && /^[a-zA-Z0-9_-]+$/.test(username);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setFirstName(user.first_name);
      setLastName(user.last_name);
      if (user.user_name) setUsername(user.user_name);
      if (user.avatar) setAvatar(user.avatar);
      setBio(user.bio || '');
      setCollaborationStyle(user.collaboration_style || '');
      setJobTitle(user.job_title || '');
      setLocation(user.location || '');
      setWebsite(user.website || '');
      setSocialLinks(user.social_links || []);
      setCompany(user.profile_company || '');
    }
  }, [user]);

  const submitForm = async (event) => {
    event.preventDefault();

    let payload = {
      email,
      first_name: firstName,
      last_name: lastName,
      bio,
      collaboration_style: collaborationStyle,
      job_title: jobTitle,
      location,
      website,
      social_links: socialLinks.filter((link) => link),
    };
    if (username) {
      payload = { ...payload, user_name: username };
    }
    if (showPassword && password) {
      payload = {
        ...payload,
        password,
        password_confirmation: password,
        existing_password: existingPassword,
      };
    }
    if (avatar) {
      payload = { ...payload, avatar };
    }

    if (company) {
      payload = { ...payload, profile_company: company };
    }

    updateUser({
      payload,
      setErrors,
      setIsUpdating: setIsLoading,
      onSuccess: () => {
        setDidSave(true);
        onSave();
      },
    });
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteUser({
        setErrors,
        setIsDeleting,
        onSuccess: () => {
          // Handle successful deletion (e.g., redirect to home page)
          alert('Account deleted successfully, redirecting to home page...');
          window.location.href = '/';
        },
      });
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const renderUsernameStatus = () => {
    if (!username || username == user?.user_name) return null;
    if (isUsernameCheckLoading) return <CircularProgress size="sm" />;
    if (isUsernameCheckError) return <ErrorIcon color="error" />;
    if (isUsernameTaken) return <ErrorIcon color="error" />;
    return <CheckCircleIcon color="success" />;
  };

  return (
    <form onSubmit={submitForm} disabled={isLoading}>
      <Stack spacing={4}>
        <Card variant="outlined">
          {!simple && (
            <>
              <Grid container>
                <Grid item xs={12} md={6} alignItems="flex-end" sx={{ display: 'flex', gap: 2 }}>
                  <Box flexGrow={1}>
                    <Typography level="h5" gutterBottom>
                      Public Profile
                    </Typography>
                    <Typography level="body-md" sx={{ color: 'text.secondary' }}>
                      This information will be displayed publicly on your profile page.
                    </Typography>
                    <Typography color="danger">{errors?.error?.response?.data?.message}</Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  justifyContent={'flex-end'}
                  alignItems="flex-start"
                  sx={{ display: 'flex' }}>
                  <CardActions>
                    <Stack direction={{ xs: 'column', sm: 'row' }} gap={2}>
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isLoading}
                        loading={isLoading || externalLoading}>
                        {buttonText}
                      </Button>
                    </Stack>
                  </CardActions>
                </Grid>
              </Grid>
              <Divider inset="none" />
            </>
          )}

          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl required>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    type="text"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    autoComplete="given-name"
                    value={firstName}
                    error={errors?.first_name ?? false}
                    onChange={(event) => setFirstName(event.target.value)}
                    disabled={false}
                  />
                  {errors?.first_name ? (
                    <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[500] })}>
                      {errors.first_name.join(' ')}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl required>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    type="text"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                    value={lastName}
                    error={errors?.last_name ?? false}
                    onChange={(event) => setLastName(event.target.value)}
                    disabled={false}
                  />
                  {errors?.last_name ? (
                    <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[500] })}>
                      {errors.last_name.join(' ')}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl required>
                  <FormLabel>Email Address</FormLabel>
                  <Input
                    type="email"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    value={email}
                    error={errors?.email ?? false}
                    onChange={(event) => setEmail(event.target.value)}
                    disabled={user?.uses_sso}
                  />
                  {errors?.email ? (
                    <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[500] })}>
                      {errors.email.join(' ')}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl required>
                  <FormLabel>Username</FormLabel>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                      type="text"
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="username"
                      label="Username"
                      name="username"
                      value={username}
                      error={errors?.user_name || isUsernameTaken}
                      onChange={(event) => setUsername(event.target.value)}
                      disabled={false}
                      endDecorator={renderUsernameStatus()}
                    />
                  </Box>
                  {errors?.user_name ? (
                    <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[500] })}>
                      {errors.user_name.join(' ')}
                    </FormHelperText>
                  ) : isUsernameTaken ? (
                    <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[500] })}>
                      This username is already taken.
                    </FormHelperText>
                  ) : !isUsernameValid && username ? (
                    <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[500] })}>
                      Username must be 3-20 characters long and contain only letters, numbers,
                      underscores, and hyphens.
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>

              {!simple && (
                <>
                  <Grid item xs={12}>
                    <FormLabel sx={{ pb: 1 }}>Avatar</FormLabel>
                    <AvatarInput onChange={setAvatar} src={user?.avatar} />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel>Bio</FormLabel>
                      <Textarea
                        minRows={3}
                        value={bio}
                        onChange={(event) => setBio(event.target.value)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel>Collaboration Style</FormLabel>
                      <Textarea
                        minRows={3}
                        value={collaborationStyle}
                        placeholder="What do you want people to know about how to work with you?"
                        onChange={(event) => setCollaborationStyle(event.target.value)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel>Job Title</FormLabel>
                      <Input
                        value={jobTitle}
                        onChange={(event) => setJobTitle(event.target.value)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel>Company</FormLabel>
                      <Input value={company} onChange={(event) => setCompany(event.target.value)} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel>Location</FormLabel>
                      <Input
                        value={location}
                        onChange={(event) => setLocation(event.target.value)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel>Website</FormLabel>
                      <Input
                        type="url"
                        value={website}
                        onChange={(event) => setWebsite(event.target.value)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Box>
                      <FormLabel>Social Links</FormLabel>
                      <SocialLinksInput value={socialLinks} onChange={setSocialLinks} />
                    </Box>
                  </Grid>
                </>
              )}
              {children && (
                <Grid item xs={12}>
                  {children}
                </Grid>
              )}
              <Grid
                item
                xs={12}
                alignItems="flex-end"
                sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}>
                <Box flexGrow={1}>
                  <Typography color="danger">{errors?.error?.response?.data?.message}</Typography>
                </Box>
                <CardActions>
                  <Button
                    type="submit"
                    color="primary"
                    size="lg"
                    disabled={isLoading}
                    loading={isLoading || externalLoading}>
                    {buttonText}
                  </Button>
                </CardActions>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {!simple && (
          <Card variant="outlined">
            <Grid container>
              <Grid item xs={12} md={6} alignItems="flex-end" sx={{ display: 'flex', gap: 2 }}>
                <Box flexGrow={1}>
                  <Typography level="h5" gutterBottom>
                    Account Settings
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider inset="none" />
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography level="title-md">
                <Skeleton loading={!user}>
                  {user?.has_password ? <span>Password</span> : <span>Using Magic Link</span>}
                </Skeleton>
              </Typography>
              <Stack direction="row" justifyContent={'space-between'} spacing={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="md"
                  onClick={() => setShowPassword(!showPassword)}>
                  {showPassword
                    ? 'Do not set password'
                    : user?.has_password
                    ? 'Change Password'
                    : 'Set Password'}
                  <Skeleton loading={!user} />
                </Button>
                {showPassword && (
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isLoading}
                    loading={isLoading || externalLoading}>
                    {buttonText}
                  </Button>
                )}
              </Stack>
            </Stack>
            {showPassword && (
              <>
                {user?.has_password && (
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel>Current Password</FormLabel>
                      <Input
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="existing_password"
                        label="Current Password"
                        type="password"
                        id="existing_password"
                        value={existingPassword}
                        error={errors?.existing_password ?? false}
                        onChange={(event) => setExistingPassword(event.target.value)}
                        autoComplete="password"
                        disabled={false}
                      />
                      {errors?.password ? (
                        <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[500] })}>
                          {errors.password.join(' ')}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Input
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      value={password}
                      error={errors?.password ?? false}
                      onChange={(event) => setPassword(event.target.value)}
                      autoComplete="new-password"
                      disabled={false}
                    />
                    {errors?.password ? (
                      <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[500] })}>
                        {errors.password.join(' ')}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12} flexDirection={'row'} display={'flex'} gap={5}>
                  <Link href="/forgot">Forgot password?</Link>
                </Grid>
              </>
            )}
          </Card>
        )}
      </Stack>
      <Snackbar
        color="tertiary"
        variant="outlined"
        open={didSave}
        autoHideDuration={4000}
        onClose={() => setDidSave(false)}
        endDecorator={
          <IconButton aria-label="Dismiss" onClick={() => setDidSave(false)} color="tertiary">
            <Close />
          </IconButton>
        }>
        <Typography level="body-md" color="tertiary" startDecorator={<Check />}>
          Profile saved!
        </Typography>
      </Snackbar>
    </form>
  );
}
