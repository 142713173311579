import AddLinkIcon from '@mui/icons-material/AddLink';
import { Box, Button, IconButton } from '@mui/joy';
import * as React from 'react';

import { Modal } from '@/components/reusable';

// eslint-disable-next-line react/display-name
const ConfirmedAction = ({
  buttonText,
  onConfirm,
  confirmButtonText,
  title,
  message,
  isConfirming,
  icon,
  variant = 'soft',
  color = 'danger',
  size = 'sm',
  disable = false,
  extraButton = null,
  cancelText = 'Cancel',
  isIcon = false,
  closeOnConfirm = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = (e) => {
    setOpen(true);
  };

  const handleConfirm = React.useCallback(() => {
    if (closeOnConfirm) {
      setOpen(false);
    }
    onConfirm();
  }, [closeOnConfirm, onConfirm]);

  return (
    <>
      {isIcon ? (
        <IconButton
          color={color}
          size={size}
          aria-label={title}
          onClick={handleClick}
          disabled={disable}>
          {icon}
        </IconButton>
      ) : (
        <Button
          variant={variant}
          color={color}
          size={size}
          onClick={handleClick}
          disabled={disable}>
          {buttonText}
        </Button>
      )}
      <Modal
        variant="dialog-warning"
        title={title}
        description={message}
        open={open}
        onClose={() => setOpen(false)}
        actions={
          <Box
            alignItems="center"
            justifyContent="flex-end"
            sx={{
              display: 'flex',
              gap: 2,
              flexDirection: { xs: 'column', sm: 'row' },
            }}>
            <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
              {cancelText}
            </Button>
            {extraButton}
            <Button
              variant="solid"
              color="danger"
              disabled={isConfirming}
              loading={isConfirming}
              onClick={handleConfirm}>
              {confirmButtonText}
            </Button>
          </Box>
        }
      />
    </>
  );
};

export default ConfirmedAction;
