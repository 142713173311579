import { NotificationsOutlined as NotificationsOutlinedIcon } from '@mui/icons-material';
import { Box, Card, Table, Typography, useTheme } from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';

import NotifierContainer from '@/components/notifiers/NotifierContainer';

const TABLE_COLUMN_NOTIFIER_DEFAULT_WIDTH = 96;
export default function NotifiersContainer({
  notifiable = 'workspace',
  notifiableId,
  notifications,
  notifiers,
  organizationId,
}) {
  if (!notifications) {
    return null;
  }

  const theme = useTheme();
  const mdPlus = useMediaQuery(theme.breakpoints.up('md'));
  const smPlus = useMediaQuery(theme.breakpoints.up('sm'));

  const notifierChannelOrder = ['Email', 'Desktop', 'Mobile', 'Slack'];
  const isWorkspace = notifiable === 'workspace';

  return (
    <Box sx={{ width: '100%' }}>
      <Card
        variant="outlined"
        sx={{
          borderRadius: 'lg',
          overflow: 'auto',
        }}>
        <Table borderAxis={mdPlus ? 'xBetween' : 'none'} size={mdPlus ? 'md' : 'sm'}>
          <thead>
            {mdPlus ? (
              <tr>
                <th style={{ width: smPlus ? 'inherit' : 200 }}>
                  <Typography
                    level="title-sm"
                    fontWeight={'lg'}
                    startDecorator={<NotificationsOutlinedIcon />}>
                    Notification
                  </Typography>
                </th>
                {notifierChannelOrder.map((channel) => (
                  <th
                    key={channel}
                    style={{
                      width:
                        channel === 'Slack' && isWorkspace
                          ? TABLE_COLUMN_NOTIFIER_DEFAULT_WIDTH * 2
                          : TABLE_COLUMN_NOTIFIER_DEFAULT_WIDTH,
                      textAlign: 'center',
                    }}>
                    <Typography level="title-sm" fontWeight={'lg'}>
                      {channel}
                    </Typography>
                  </th>
                ))}
              </tr>
            ) : null}
          </thead>
          <tbody>
            {notifiers?.map((notifier, index) => (
              <NotifierContainer
                key={notifier.id}
                notifier={notifier}
                organizationId={organizationId}
                notifiableId={notifiableId}
                notifiable={notifiable}
                notifier_config={notifications[notifier.triggering_event]}
                isLast={index === notifiers.length - 1}
              />
            ))}
          </tbody>
        </Table>
      </Card>
    </Box>
  );
}
