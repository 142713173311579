import { axios, isElectron } from '@groupthinkai/groupthink';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useSWR from 'swr';

export const useAuth = ({
  middleware,
  redirectIfAuthenticated,
  redirectIfUnauthenticated = '/signin',
} = {}) => {
  const router = useRouter();
  const query = router.query;

  const {
    data: user,
    error,
    mutate,
    isLoading,
  } = useSWR(
    '/v1/users/me',
    () =>
      axios.get('/v1/users/me').then((res) => {
        return res.data;
      }),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      keepPreviousData: true,
      onErrorRetry: (error) => {
        // Never retry on 401, they're unauthenticated.
        if (error.status === 401) return;
      },
    }
  );

  const isEmployee = user?.email?.endsWith('@groupthink.com') ?? false;

  const csrf = () => axios.get('/sanctum/csrf-cookie');

  const signup = async ({ setErrors, setIsLoading, onSuccess, ...props }) => {
    await csrf();

    setIsLoading ? setIsLoading(true) : null;
    setErrors ? setErrors([]) : null;

    axios
      .post('/signup', props)
      .then(() => {
        mutate().then(() => (setIsLoading ? setIsLoading(false) : null));
        onSuccess ? onSuccess() : null;
        setIsLoading ? setIsLoading(false) : null;
      })
      .catch((error) => {
        if (error.response.status !== 422) throw error;

        setErrors ? setErrors(error.response.data.errors) : null;
        setIsLoading ? setIsLoading(false) : null;
      });
  };

  const startPasswordless = async ({
    setErrors,
    setStatus,
    setIsLoggingIn,
    onSuccess,
    ...payload
  }) => {
    // await csrf();

    setErrors ? setErrors([]) : null;
    setStatus(null);
    setIsLoggingIn ? setIsLoggingIn(true) : null;

    axios
      .post('/login/passwordless', payload)
      .then((res) => {
        mutate().then(() => (setIsLoggingIn ? setIsLoggingIn(false) : null));
        Boolean(onSuccess) && onSuccess(res.data?.data ?? res.data);
      })
      .catch((error) => {
        setIsLoggingIn ? setIsLoggingIn(false) : null;
        setErrors ? setErrors(error.response.data.errors) : null;
      });
  };

  const loginPasswordless = async ({
    setErrors,
    setStatus,
    setIsLoggingIn,
    onSuccess,
    ...payload
  }) => {
    await csrf();

    setErrors ? setErrors([]) : null;
    setStatus(null);
    setIsLoggingIn ? setIsLoggingIn(true) : null;

    axios
      .post('/login/link', payload)
      .then((res) => {
        setIsLoggingIn ? setIsLoggingIn(false) : null;
        Boolean(onSuccess) && onSuccess(res.data?.data ?? res.data);
      })
      .catch(() => {
        setIsLoggingIn ? setIsLoggingIn(false) : null;
        setErrors ? setErrors([{ message: 'failed' }]) : null;
      });
  };

  const loginGoogle = async ({ setErrors, setStatus, setIsLoggingIn, onSuccess, ...payload }) => {
    await csrf();

    setErrors ? setErrors([]) : null;
    setStatus(null);
    setIsLoggingIn ? setIsLoggingIn(true) : null;

    axios
      .post('/login/google', payload)
      .then((res) => {
        setIsLoggingIn ? setIsLoggingIn(false) : null;
        Boolean(onSuccess) && onSuccess(res.data?.data ?? res.data);
      })
      .catch(() => {
        setIsLoggingIn ? setIsLoggingIn(false) : null;
        setErrors ? setErrors([{ message: 'failed' }]) : null;
      });
  };

  const login = async ({ setErrors, setStatus, setIsLoggingIn, onSuccess, ...payload }) => {
    await csrf();

    setErrors ? setErrors([]) : null;
    setStatus(null);
    setIsLoggingIn ? setIsLoggingIn(true) : null;

    axios
      .post('/login', payload)
      .then(() => {
        mutate().then(() => (setIsLoggingIn ? setIsLoggingIn(false) : null));
        onSuccess ? onSuccess() : null;
      })
      .catch((error) => {
        setIsLoggingIn ? setIsLoggingIn(false) : null;
        setErrors ? setErrors(error.response.data.errors) : null;
      });
  };

  const forgotPassword = async ({ setErrors, setStatus, email }) => {
    await csrf();

    setErrors ? setErrors([]) : null;
    setStatus(null);

    axios
      .post('/forgot', { email })
      .then((response) => setStatus(response.data.status))
      .catch((error) => {
        if (error.response.status !== 422) throw error;

        setErrors ? setErrors(error.response.data.errors) : null;
      });
  };

  const resetPassword = async ({ setErrors, setStatus, ...props }) => {
    await csrf();

    setErrors ? setErrors([]) : null;
    setStatus(null);

    axios
      .post('/reset', { token: query.token, ...props })
      .then((response) => router.push('/signin?reset=' + btoa(response.data.status)))
      .catch((error) => {
        if (error.response.status !== 422) throw error;

        setErrors ? setErrors(error.response.data.errors) : null;
      });
  };

  const resendEmailVerification = ({ setStatus }) => {
    axios
      .post('/email/verification-notification')
      .then((response) => setStatus(response.data.status));
  };

  const logout = async () => {
    if (isElectron) {
      window.electron.sendToken(null);
    }

    if (!error) {
      await axios.post('/logout').then(() => mutate());
    }

    await logoutRedirect();
  };

  const logoutRedirect = async () => {
    if (isElectron) {
      window.electron.sendToken(null);
    } else {
      if (window.location.pathname !== redirectIfUnauthenticated) {
        const queryParams = new URLSearchParams(window.location.search);
        let query = { redirectTo: window.location.pathname };
        const invited_by = queryParams.get('invited_by');
        if (invited_by) {
          query = { ...query, invited_by };
        }

        router.push({ pathname: redirectIfUnauthenticated, query });
      }
    }
  };

  const fetchDesktopToken = async () =>
    await axios.get('/auth/token').then((res) => {
      return res.data;
    });

  useEffect(() => {
    if (middleware === 'guest' && redirectIfAuthenticated && user) {
      console.log('Redirecting authenticated user from guest route to:', redirectIfAuthenticated);
      router.push(redirectIfAuthenticated);
    }

    if (middleware === 'auth' && error) {
      if (error?.response?.status === 401) {
        console.log('Unauthorized user detected, redirecting to:', redirectIfUnauthenticated, {
          currentPath: window.location.pathname,
          error: error?.response?.data,
        });
        logoutRedirect();
      }
    }
  }, [user, error, isLoading, router, middleware, redirectIfAuthenticated, logout]);

  return {
    user,
    isLoading,
    signup,
    login,
    mutate,
    startPasswordless,
    loginPasswordless,
    loginGoogle,
    forgotPassword,
    resetPassword,
    resendEmailVerification,
    logout,
    logoutRedirect,
    fetchDesktopToken,
    isEmployee,
  };
};
